import React, { useEffect, useState } from "react"

import { Row, Col, Card, CardBody, Button } from "reactstrap"
import { Link } from "react-router-dom"

import avatar1 from "../../assets/images/users/avatar-1.jpg"
import profileImg from "../../assets/images/profile-img.png"
import { getProfile } from "assistance/promoterClient_service"
import DialogModal from "Components/Message Modal"

interface ProfileData {
  firstName: string;
  lastName: string;
  // Add other fields if necessary
}
interface WelcomProps{
  setActiveModal?: any;
}

const WelComeback:  React.FC<WelcomProps> = ({ setActiveModal}) => {
  const [profileData, setProfileData] = useState<ProfileData | undefined>(undefined);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await getProfile();

        if (response.data && response.status === 200) {
          // console.log(response.data?.data?.firstName);
          setProfileData(response.data?.data);
        } else if (response.data && response.status === 203) {
          setActiveModal(true)


        }


      } catch (error) {
        console.error('Failed to fetch profile data', error);
      }
    };

    fetchProfile();
  }, []);


  return (
    <>

      <React.Fragment>

        <Card className="overflow-hidden">
          <div className="" style={{ backgroundColor: "#642e5c" }}>
            <Row>
              <Col xs={7}>
                <div className="text-white p-3">
                  <h5 className="" >Welcome Back !</h5>
                  <p >Insider Dashboard</p>
                </div>
              </Col>
              <Col xs={5} className="align-self-end">
                <img src={profileImg} alt="" className="img-fluid" />
              </Col>
            </Row>
          </div>
          <CardBody className="pt-0">
            <Row>
              <Col sm={4}>
                <div className="avatar-md profile-user-wid mb-4">
                  <img
                    src={`/person-7243410_1280.png`}
                    alt=""
                    className="img-thumbnail rounded-circle"
                  />
                </div>
                <h5 className="font-size-15 text-truncate">
                  {profileData?.firstName ? `${profileData.firstName} ${profileData.lastName}` : ''}
                </h5>
                <p className="text-muted mb-0 text-truncate">{``}</p>
              </Col>

              <Col sm={8}>
                <div className="pt-4">
                  <Row>
                    <Col xs={6}>
                      <h5 className="font-size-15"></h5>
                      <p className="text-muted mb-0">Active Lead</p>
                    </Col>
                    <Col xs={6}>
                      <h5 className="font-size-15"></h5>
                      <p className="text-muted mb-0">Client</p>
                    </Col>
                  </Row>
                  <div className="mt-4">
                    <Link to="/manageProfile" className="btn  text-white btn-sm" style={{ backgroundColor: "#642e5c" }}>
                      View Profile <i className="mdi mdi-arrow-right ms-1"></i>
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </React.Fragment>
    </>

  )
}
export default WelComeback
