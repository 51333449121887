import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Input,
  Label,
  Form,
  FormFeedback,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import profileImg from "../../assets/onboarding_bg.svg";
// import logoImg from "../../assets/images/logo.svg";
import { createSelector } from "reselect";
import { apiError } from "../../slices/thunk";
import {
  fetchCountryList,
  verifyEmail,
} from "../Authentication/Registerfunctions"; // Import your JS functions
import OTPModal from "Components/partner-register-modal/partner-register-modal";
import { getStates } from "assistance/static_api";
import OTPRegModal from "Components/reg-otp/reg";
import Loader from "./loader";

interface Country {
  id: number;
  sortname: string;
  name: string;
  phoneCode: number;
}
interface State {
  id: number;
  name: string;
  country_id: number;
}



const RegisterOTP = () => {
  const [selectedCode, setSelectedCode] = useState("91");
  const [selectCode, setSelectCode] = useState("101");
  const [phoneNum, setPhoneNum] = useState('');
  const [countryList, setCountryList] = useState<Country[]>([]);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [showOTP, setShowOTP] = useState(false);
  const [show, setShow] = useState(false);
  const [shows, setShows] = useState(false);

  const [data, setData] = useState(['']);
  const [stateList, setStateList] = useState<State[]>([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("IN");
  const [selectedRegion, setSelectedRegion] = useState('');

  const handleSelectChange = (e) => {
    setSelectedRegion(e.target.value);
  };
  const [formData, setFormData] = useState({
    email: "",
    username: "",
    password: "",
    state:"",
    type: "",
    phone: "",
    country: "India",
  });
  const [selectedCountryDetail, setSelectedCountryDetail] = useState({
    id: "101",
    code: "IN",
    name: "India",
  });

  useEffect(() => {

    // debugger
    // Get the 'fromData' query parameter from the URL
    const urlParams = new URLSearchParams(window.location.search);
    const fromDataParam = urlParams.get('fromData');

    if (fromDataParam) {
      // Decode and parse the data

      const decodedData = JSON.parse((fromDataParam));

      // Store the data in an array
      // console.log(decodedData)
      setData(decodedData)
      // debugger
      setFormData((prevFormData) => ({
        ...prevFormData,
        country: decodedData?.country,
        email: decodedData?.email,
        state:decodedData?.state,
        username: decodedData?.username,
        password: decodedData?.password,
        type: "",
        phone:"91" + "-" + decodedData?.phone,
      }));

      sessionStorage.setItem('datas',JSON.stringify(decodedData))


      verifyEmail(decodedData, setShowOTP,setShows)
        .then((result) => {
          // console.log(result.message);
        })
        .catch((error) => {
          console.error("Verification failed", error);
        });

    } else {
      console.log('No fromData found in the URL.');
    }
    const cachedCountries = localStorage.getItem('countryList');
    if (cachedCountries) {
      setCountryList(JSON.parse(cachedCountries));
      setLoading(false);
    } else {
      const getCountries = async () => {
        try {
          const countries = await fetchCountryList();
          setCountryList(countries);
          localStorage.setItem('countryList', JSON.stringify(countries));
        } catch (err) {
          console.error("Error fetching countries:", err);
        } finally {
          setLoading(false);
        }
      };
      getCountries();
    }

  }, []);

  useEffect(() => {
    if (selectCode) {
      // Check if states for the selected country are already cached
      const cachedStates = sessionStorage.getItem(`states_${selectCode}`);
      if (cachedStates) {
        setStateList(JSON.parse(cachedStates)); // Use cached states
      } else {
        const getState = async (selectCode :any) => {
          try {
            const data = await getStates(selectCode); // Call API for states based on country selectedCode
            setStateList(data?.data?.data);
            sessionStorage.setItem(`states_${selectCode}`, JSON.stringify(data?.data?.data)); // Cache states for the selected country
          } catch (err) {
            console.error("Error fetching states:", err);
          } finally {
            setLoading(false);
          }
        };
        getState(selectCode);
      }
    }
  }, [selectCode]);

  // console.log(stateList)




  const handleCodeChange = (e) => {
    setSelectedCode(e.target.value);
  };

  // Handle country selection and update phone code
  const handleCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCountry = e.target.value;
    handleInputChange(e); // Keep this to update form data
  
    // Ensure phoneCode is converted to a string
    const country = countryList.find((c) => c.name === selectedCountry);
    const phoneCodeString = country?.phoneCode.toString() || "";
    const codeString = country?.id.toString() || "";

    setSelectedCode(phoneCodeString);
    setSelectCode(codeString)
    // Dynamically set country details
// Dynamically set country details
setSelectedCountryDetail({
  id: country?.id?.toString() || "", // Convert id to string if it exists, otherwise use an empty string
  code: country?.phoneCode?.toString() || "", // Convert phoneCode to string if it exists, otherwise use an empty string
  name: country?.name || "",
});

  
    setFormData((prevFormData) => ({
      ...prevFormData,
      country: selectedCountry,
    }));
  
    // console.log(phoneCodeString); // Updated to log the local variable directly
  };
  

  const dispatch = useDispatch<any>();

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      username: "",
      password: "",
      type: "",
      phone: "",
      country: formData.country || "", // Bind initialValues to formData
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email format")
        .required("Please Enter Your Email"),
      username: Yup.string()
        .matches(/^[A-Za-z\s]/, "Username must contain only letters")
        .min(3, "Username must be at least 3 characters long")
        .required("Please Enter Your Username"),
      password: Yup.string()
        .min(8, "Password must be at least 8 characters long")
        .required("Please Enter Your Password"),

      type: Yup.string().required("Please Choose a type"),
      phone: Yup.string()
        .matches(/^[0-9]+$/, "Phone number must contain only digits")
        .min(10, "Phone number must be at least 10 digits")
        .max(10, "Phone number max 10 digits")
        .required("Please Enter Your Phone number"),
      country: Yup.string().required("Please Select a Country"),
    }),
    onSubmit: (values) => {

      const combinedPhone = `${selectedCode}-${values.phone}`;
      const finalData = {
        ...values,
        phone: combinedPhone,
      };
      // console.log(finalData);

      setFormData({
        username: finalData?.username,
        email: finalData?.email,
        phone: "+" + finalData?.phone,
        state:selectedState,
        country: finalData?.country,
        password: finalData.password, // Corrected this
        type: finalData?.type,
      });

      verifyEmail(finalData, setShowOTP)
        .then((result) => {
          // console.log(result.message);
        })
        .catch((error) => {
          console.error("Verification failed", error);
        });
    },
  });

  const selectProperties = createSelector(
    (state: any) => state.Account,
    (account) => ({
      user: account.user,
      registrationError: account.registrationError,
      loading: account.loading,
    })
  );

  const { user, registrationError } = useSelector(selectProperties);

  useEffect(() => {
    dispatch(apiError());
  }, [dispatch]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'phone') {
      setPhoneNum(value)
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    validation.setFieldValue(name, value); // Update Formik field value
  };

  return (
    <React.Fragment>
      <div style={{
        backgroundImage: `url(${profileImg})`, // Your image URL here
        backgroundSize: "cover", // Ensure image covers the entire area
        backgroundPosition: "center",
        height: "100vh", // Full viewport height
        width: "100vw", // Full viewport width
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
       flexDirection:'column',
      }}>
      
      {showOTP === false &&(
     <>
     <Loader/>
     </>
      )}
        {showOTP && (
          <OTPRegModal
            isOpen={showOTP}
            toggle={() => setShowOTP(false)} // Update toggle function
            formData={formData}
            selectedRegion={selectedRegion}
          // setShowOTP={setShowOTP}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default RegisterOTP;
