import React from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import { Link } from "react-router-dom"
import { Social } from "./type"

const SocialSource: React.FC = () => {

  const socials: Social[] = [
    { title: "MQL", bgColor: "bg-primary", iconClass: "mdi-account-supervisor", description: "0" },
    { title: "PQL ", bgColor: "bg-info", iconClass: "mdi-account-supervisor", description: "0" },
    { title: "SQL", bgColor: "bg-pink", iconClass: "mdi-account-supervisor", description: "0" },
    { title: "Sales", bgColor: "bg-yellow", iconClass: "mdi-account-supervisor", description: "0" },

  ]

  return (
    <React.Fragment>
      <Card style={{height:"320px"}}>
      <CardBody >
          <CardTitle className="mb-4 ">Lead Category</CardTitle>
          <div className="text-center">
            {/* <div className="avatar-sm mx-auto mb-4">
              <span className="avatar-title rounded-circle  font-size-24"  style={{ backgroundColor: "#642e5c" }}>
                <i className="mdi mdi-facebook text-white"></i>
              </span>
            </div> */}
            <p className="font-16 text-muted mb-3 mt-3"></p>
            <h5>
              {/* <Link to="#" className="text-dark">
                Lead <span className="text-muted font-16"></span>{" "}
              </Link> */}
            </h5>
            {/* <p className="text-muted mt-4">
            Lead Category Manager you will be responsible for the leadership of the General Goods and Services cluster and the daily management of the Category Managers in your team. 
            </p> */}
            {/* <Link to="#" className=" font-16"  style={{ color: "#642e5c" }}>
              Learn more <i className="mdi mdi-chevron-right"></i>
            </Link> */}
          </div>
          <Row className="mt-5">
            {(socials || [])?.map((social: Social, key: number) => (
              <Col xs={3} key={"_li_" + key}>
                <div className="social-source text-center mt-3">
                  <div className="avatar-xs mx-auto mb-3">
                    <span className={"avatar-title rounded-circle " + social.bgColor + " font-size-16"} >
                      <i className={"mdi " + social.iconClass + " text-white"}></i>
                    </span>
                  </div>
                  <h5 className="font-size-15">{social.title}</h5>
                  <p className="text-muted mb-0">{social.description} </p>
                </div>
              </Col>
            ))}
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default SocialSource
