import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import styles from "./wallet-modal.module.scss";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { round } from "lodash";
import {
  deletePromoterPaymentmethod,
  getProfile,
  getPromoterPaymentmethod,
  getTransaction,
  getTranscationSummary,
  setPrimaryAccount,
} from "assistance/promoterClient_service";
import WalletOTP from "./wallet-modal";
import ModalSetting from "./modal-setting";
import ModalMess from "Components/Message Modal";
import WalletWithdraw from "./wallet-modal-withdraw";

interface ProfileData {
  firstName: string;
  lastName: string;
  Phone: string;
  email: string;
  phone: any;

  // Add other fields if necessary
}

interface MethodData {
  IFSCCode: string;
  IsPrimary: boolean;
  RefId: string;
  RefNumber: string;
  SWIFTCode: string;
  Type: string;
  method: string;
  methodName: string;
  seq: number;
  status: string;
}

interface TransactionData {
  trnPostedAt: string;
  dueAt: string;
  promoterId: string;
  promoterName: string;

  transactionId: string;
  trnType: string;
  trnValue: any;
  trnAt: string;
  trnDesc: string;
  trnStatus: string;
  trnStateDesc: string;
  trnState: string;
}

interface TransactionSummary {
  commissionAddedTotal: any;
  
}


document.title = " Skartio - Insider";


const Wallet: React.FC = () => {


  const [profileData, setProfileData] = useState<ProfileData | undefined>(
    undefined
  );

  const [show, setShow] = useState(false);
  const [showDate, setShowDate] = useState(false);
  const [comissionRate, setComissionRate] = useState('');
  const [comissionPoint, setComissionPoint] = useState('');

  const [currentPage, setCurrentPage] = useState(1);
  const rewardsPerPage = 5; // Customize the number of rewards per page

  const [rewardData, setRewardData] = useState<TransactionData[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [Pages, setPages] = useState(0);

  const indexOfLastReward = currentPage * rewardsPerPage;
  const indexOfFirstReward = indexOfLastReward - rewardsPerPage;
  const currentRewards = rewardData.slice(
    indexOfFirstReward,
    indexOfLastReward
  );

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const [action, setAction] = useState<boolean>(false);
  const [methods, setMethods] = useState(false);
  const [methodsdata, setMethoddata] = useState<MethodData[]>([]);
  const [showSetting, setShowSetting] = useState(false);
  const [currentId, setCurrentId] = useState("");
  const [showOTP, setShowOTP] = useState(false);
  const [Withdraw, setWithdraw] = useState(false);

  const handleAddAccount = () => {
    setShowOTP(true);
  };

  const handleWithdraw = () => {
    setWithdraw(true);
  };

  useEffect(() => {
    getpaymentmethod();
  }, [action]);

  const getpaymentmethod = async () => {
    try {
      const method = await getPromoterPaymentmethod(); // Call the function from JS file
      setMethoddata(method?.data?.data?.data);
    } catch (err) {
      console.error("Error fetching countries:", err);
    } finally {
    }
  };

  const formatPhoneNumber = (phoneNumber) => {
    const phoneStr = phoneNumber.toString();
    const hiddenPart = phoneStr.slice(0, -4).replace(/\d/g, "*");
    const visiblePart = phoneStr.slice(-4);
    return hiddenPart + visiblePart;
  };
  const formatEmailUsername = (email) => {
    const [username, domain] = email.split("@");
    const hiddenPart = username.slice(0, -2).replace(/./g, "*");
    const visiblePart = username.slice(-2);
    return hiddenPart + visiblePart + "@" + domain;
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await getProfile();

        if (response.data && response.status === 200) {
          setProfileData(response.data?.data); // Update profileData using setProfileData
        } else if (response.data && response.status === 203) {
          setShow(true);

          // window.location.href="/createAccount";
        }
      } catch (error) {
        console.error("Failed to fetch profile data", error);
      }
    };

    fetchProfile();
  }, []);

  const handleSetting = async (id: any) => {
    setShowSetting(true);
    setCurrentId(id);
  };

  useEffect(() => {
    const fetchSummary = async () => {
      try {
        const response = await getTranscationSummary();

        if (response.data && response.status === 200) {
          setComissionPoint(response.data?.data?.pointAddedTotal);
          setComissionRate(response.data?.data?.commissionAddedTotal)

        } else if (response.data && response.status === 203) {
          // window.location.href="/createAccount";
        }
      } catch (error) {
        console.error("Failed to fetch profile data", error);
      }
    };

    fetchSummary();
  }, []);
  useEffect(() => {
    const fetchTransactionlist = async () => {
      try {
        const response = await getTransaction(currentPage, 6);

        if (response.data && response.status === 200) {
          setRewardData(response.data?.data?.data);
          const pages = round(response.data?.data?.totalCount / 5);

          setTotalPages(pages);
          setPages(response.data?.data?.totalCount);
        } else if (response.data && response.status === 203) {
          // window.location.href="/createAccount";
        }
      } catch (error) {
        console.error("Failed to fetch profile data", error);
      }
    };

    fetchTransactionlist();
  }, [currentPage]);

  const dateFormate = (date: Date): string => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = date.toLocaleString("default", { month: "long" }); // Get full month name
    const year = date.getFullYear();
    const formattedDate = `${month} ${day}, ${year}`;
    return formattedDate; // Return the formatted date
  };

 
  const dateFormateDay = (date: Date): string => {
    console.log(date);
    const currentDate = new Date();
    // if (currentDate === date) {
    //   setShowDate(true);
    // } else {
    //   setShowDate(false);
    // }

    const day = date.getDate().toString().padStart(2, "0");
    const month = date.toLocaleString("default", { month: "short" }); // Get full month name
    const year = date.getFullYear();
    const formattedDate = `${month} ${day}, ${year}`;
    return formattedDate; // Return the formatted date
  };

  return (
    <React.Fragment>
      {show && (
        <ModalMess
          button1Text="Continue"
          button2Text="Cancel & Logout"
          isOpen={show}
          toggle={() => setShow(false)} // Update toggle function
          //       onButton1Click={handleButton1Click}
          // onButton2Click={handleButton2Click}
        />
      )}
      <ToastContainer />

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Wallet " breadcrumbItem="Wallet" />
          <div className="row">

         
             
            <div className="col-xl-8">

              <div className="row">
              
              <div className="row">
              <div className="col-xl-6">
 
<Card><CardBody>
<div className="row">
                    <div className="col-sm-6">
                      <div>
                        <p className="text-muted mb-2">Available Balance</p>
                        <h5> 0</h5>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="text-sm-end mt-4 mt-sm-0">
                        <p className="text-muted mb-2">
                          Create a Withdrawal Request
                        </p>
                        <a
                          className="btn btn-primary btn-sm w-md"
                          href="#"
                          style={{
                            color: " white",
                            backgroundColor: "#642e5c",
                            border: "none",
                          }}

                          onClick={handleWithdraw}
                        >
                   

                          Withdraw
                        </a>
                      </div>
                    </div>
                  </div>
          </CardBody>
        </Card>
        </div>
              <div className="col-xl-3">

<Card><CardBody>
<div className="mt-4 mt-sm-0">
            {/* <div className="font-size-24  mb-2">
              <i className="bx bx-wallet"></i>
            </div> */}
            <p className="text-muted mb-2 "><b>Commission</b></p>
            <h5>  <i className="mdi mdi-currency-inr"></i> {" "}{comissionRate}</h5>
          
          </div>
          </CardBody>
        </Card>
        </div>
              <div className="col-xl-3">
              <Card><CardBody>
              <div>
              <div className="mt-4 mt-sm-0">
           
            <p className="text-muted mb-2 "><b>Point</b></p>
            <h5>   {comissionPoint}</h5>
          
          </div> 
                      </div>
                        </CardBody>
                      </Card>
              </div>
            
                   
                     
              </div>
              <div className="row">
              <div className="col-xl-12">
 <div className=" text-sm-center pb-2">
 Note : Reward commissions will be credited to your wallet once the reward processing is complete.</div></div></div>
              </div>
              <div className="card">
                <div className={`card-body ${styles["datalist"]} `}>
                  <h4 className="mb-4 card-title">Transaction</h4>
                  {/* <ul className="nav-tabs-custom nav nav-tabs">
            <li className="nav-item"><a className="nav-link">All</a>
            </li><li className="nav-item"><a className="nav-link">Buy</a>
            </li><li className="nav-item"><a className="nav-link">Sell</a>
            </li></ul> */}
                  <div className="mt-4">
                    <div className="mb-2 row"></div>
                    <div className="table-responsive">
                      <table className="table-hover dt-responsive nowrap dataTable no-footer dtr-inline table table-hover">
                        <thead>
                          <tr>
                            <th className="sorting sorting_desc">
                              <div>Id No</div>
                            </th>
                            <th className="sorting sorting_desc">
                              <div>Date</div>
                            </th>
                            <th className="sorting sorting_desc">
                              <div>Description</div>
                            </th>
                            <th className="sorting sorting_desc">
                              <div>Amount</div>
                            </th>
                            <th className="sorting sorting_desc">
                              <div>Status</div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {rewardData &&
                            rewardData.map((item, index) => {
                              return (
                                <tr>
                                  <th scope="row">{item?.transactionId}</th>
                                  <td>
                                    {dateFormate(new Date(item?.trnPostedAt))}
                                  </td>
                                  <td>{item?.trnDesc ? item?.trnDesc : ""}</td>
                                  {/* <td>{dateFormate(new Date(item?.dueAt))}</td> */}
                                  <td
                                    className={`${styles["rewards"]}
                                 ${
                                   item.trnType === "T1"
                                     ? styles["Point-C"]
                                     : item.trnType === "T2"
                                     ? styles["Point-D"]
                                     : item.trnType === "T3"
                                     ? styles["commission-C"]
                                     : item.trnType === "T4"
                                     ? styles["commission-D"]
                                     : item.trnType === "T5"
                                     ? styles["desposite"]
                                     : item.trnType === "T6"
                                     ? styles["withdraw"]
                                     : ""
                                 }`}
                                  >
                                    {item.trnType === "T3" || "T5" ? (
                                      <>
                                        <i className="mdi mdi-currency-inr"></i>{" "}
                                        {item.trnValue ? item.trnValue : ""}
                                      </>
                                    ) : item.trnType === "T4" || "T5" ? (
                                      <>
                                        <i className="mdi mdi-currency-inr"></i>{" "}
                                        {item.trnValue ? item.trnValue : ""}
                                      </>
                                    ) : item.trnType === "T1" || "T2" ? (
                                      <>
                                        <i className="bi bi-gem"></i>{" "}
                                        {item.trnValue ? item.trnValue : ""}
                                      </>
                                    ) : (
                                      `${item.trnValue ? item.trnValue : ""}`
                                    )}
                                  </td>
                                  <td
                                    className={`${styles["rewards"]}
${
  item.trnState === "201"
    ? styles["Intiated"]
    : item.trnState === "251"  
    ? styles["Process"]
    : item.trnState === "301" 
    ? styles["Captured"]
    : item.trnState === "401"  
    ? styles["Expired"]
    : item.trnState === "402"  
    ? styles["Failed"]
    : item.trnState === "403" 
    ? styles["Declined"] 
    : item.trnState === "501"  
    ? styles["Hold"]
     :
    ""
}`}
                                  >
                                  <p  className={`${styles["descData"]}`}>
                                    <span>{ item.trnState === "251"  ? "Under Processing" :item?.trnStateDesc}</span>
                                    <span className={`text-dark ${styles["lists"]}`} >{` ${
                                    item.trnState === "201"
                                      ? "Intiated Date "
                                      : item.trnState === "251"  
                                      ? "Processing Date"
                                      : item.trnState === "301" 
                                      ? "Captured"
                                      : item.trnState === "401"  
                                      ? "Expired Date"
                                      : item.trnState === "402"  
                                      ? "Failed Date"
                                      : item.trnState === "403" 
                                      ? "Declined Date"
                                      : item.trnState === "501"  
                                      ? "Hold Date"
                                       :
                                      ""
                                  } ${" "} ( ${dateFormateDay(
                                    new Date(item?.dueAt)
                                  )} )`} </span>

                                   </p>

                                  
                                    
                                    </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                      {rewardData.length < 0 && (
                        <div className="d-flex justify-content-center">
                          Currently No record
                        </div>
                      )}
                        {Pages > 6 && (
              <div className={`mt-2 ${styles.pagination}`}>
                {[...Array(totalPages)].map((_, index) => (
                  <button
                    key={`page-${index}`}
                    onClick={() => handlePageChange(index + 1)}
                    className={`${styles["page-item"]} ${
                      currentPage === index + 1 ? styles.active : ""
                    }`}
                  >
                    {index + 1}
                  </button>
                ))}
              </div>
            )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-4">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex">
                    <div className="flex-shrink-0 me-4">
                      <i
                        className="mdi mdi-account-circle  h1"
                        style={{ color: "#642e5c" }}
                      ></i>
                    </div>
                    <div className="flex-grow-1">
                      <div className="text-muted">
                        <h5>{profileData?.firstName}</h5>

                        <p className="mb-1">{profileData?.email}</p>
                        <p className="mb-1">{profileData?.phone}</p>

                        {/* <p className="mb-0">Id no: #SK0234</p> */}
                      </div>
                    </div>
                    <div className="ms-2 dropdown dropend">
                      <a
                        aria-haspopup="true"
                        className=""
                        aria-expanded="false"
                      >
                        {/* <i className="mdi mdi-dots-horizontal font-size-18"></i> */}
                      </a>
                      {/* <div  role="menu" aria-hidden="true" className="dropdown-menu" data-bs-popper="static">
                                <a href="#"  role="menuitem" className="dropdown-item">Action</a>
                                <a href="#" role="menuitem" className="dropdown-item">Another action</a>
                                <a href="#" role="menuitem" className="dropdown-item">Something else</a>
                            </div> */}
                    </div>
                  </div>
                </div>
                <div className="border-top card-body">
                  <div className="d-flex justify-content-center">
                    <button
                      type="button"
                      className="btn d-flex justify-content-center mb-3 w-sm"
                      style={{
                        color: " white",
                        backgroundColor: "#642e5c",
                        border: "none",
                      }}
                      onClick={handleAddAccount}
                    >
                      <i className="mdi mdi-plus d-block font-size-14"></i> Add
                      Account Details
                    </button>
                  </div>
                  {methodsdata &&
                    methodsdata.map((item, index) => {
                      return (
                        <>
                          <div
                            className="card "
                            style={{
                              border: "1px,solid black",
                              height: "140px",
                              width: "100%",
                              fontSize: "15px",
                              padding: "10px",
                            }}
                            key={index}
                          >
                            <div className="row">
                              {item?.method === "UPI" ? (
                                <>
                                  <div className="col-2">
                                    <div className={`${styles["wallet-icon"]}`}>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 48 48"
                                      width="48px"
                                      height="48px"
                                      baseProfile="basic"
                                    >
                                      <polygon
                                        fill="#388e3c"
                                        points="29,4 18,45 40,24"
                                      />
                                      <polygon
                                        fill="#f57c00"
                                        points="21,3 10,44 32,23"
                                      />
                                    </svg>
                                    </div>
                                  </div>
                                  <div
                                    className={` col-4  ${styles["wallet-font"]}`}
                                  >
                                    <p>Payment Method</p>
                                    <p>UPI ID</p>
                                  </div>
                                  <div
                                    className={` col-4  ${styles["wallet-font"]}`}
                                  >
                                    <p>
                                      <b>{item?.methodName}</b>
                                    </p>
                                    <p>
                                      <b>{formatEmailUsername(item?.RefId)}</b>{" "}
                                    </p>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="col-2">
                                    <i
                                      className={`mdi mdi-bank   ${styles["wallet-icon"]}`}
                                      // style={{ fontSize: "50px" }}
                                    ></i>
                                  </div>

                                  <div
                                    className={` col-4  ${styles["wallet-font"]}`}
                                  >
                                    <p>Payment Method</p>
                                    <p>Account Type</p>
                                    <p>Bank Account</p>
                                  </div>
                                  <div
                                    className={` col-4  ${styles["wallet-font"]}`}
                                  >
                                    <p>
                                      <b>{item?.methodName}</b>
                                    </p>
                                    <p>
                                      <b>{item?.Type}</b>{" "}
                                    </p>
                                    <p>
                                      <b>
                                        {formatPhoneNumber(item?.RefNumber)}
                                      </b>
                                    </p>
                                  </div>
                                </>
                              )}
                              <div className="col-2">
                                <button
                                  type="button"
                                  style={{
                                    backgroundColor: "transparent",
                                    outline: "none",
                                    border: "none",
                                  }}
                                  onClick={() => handleSetting(item?.seq)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 128 512"
                                    width={20}
                                    height={20}
                                  >
                                    <path d="M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z" />
                                  </svg>
                                </button>
                                {/* <div className="col-1">
                                <button
                                  type="button"
                                  style={{
                                    backgroundColor: "transparent",
                                    outline: "none",
                                    border: "none",
                                  }}
                                  onClick={() => DeleteProfile(item?.seq)}
                                >
                                  <i
                                    className="mdi mdi-delete-outline d-block "
                                    style={{ fontSize: "25px" }}
                                  ></i>
                                </button>
                              </div> */}
                                {item?.IsPrimary === true ? (
                                  <button
                                    className="mt-3"
                                    type="button"
                                    style={{
                                      backgroundColor: "transparent",
                                      outline: "none",
                                      border: "none",
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 384 512"
                                      width="25"
                                      height="25"
                                    >
                                      <path
                                        d="M0 48V487.7C0 501.1 10.9 512 24.3 512c5 0 9.9-1.5 14-4.4L192 400 345.7 507.6c4.1 2.9 9 4.4 14 4.4c13.4 0 24.3-10.9 24.3-24.3V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48z"
                                        fill="#642e5c"
                                      />
                                    </svg>
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="mt-3"
                                    style={{
                                      backgroundColor: "transparent",
                                      outline: "none",
                                      border: "none",
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 384 512"
                                      width="25"
                                      height="25"
                                    >
                                      <path
                                        d="M0 48C0 21.5 21.5 0 48 0l0 48 0 393.4 130.1-92.9c8.3-6 19.6-6 27.9 0L336 441.4 336 48 48 48 48 0 336 0c26.5 0 48 21.5 48 48l0 440c0 9-5 17.2-13 21.3s-17.6 3.4-24.9-1.8L192 397.5 37.9 507.5c-7.3 5.2-16.9 5.9-24.9 1.8S0 497 0 488L0 48z"
                                        fill="#642e5c"
                                      />
                                    </svg>
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                </div>
               
              
              </div>
            </div>


          </div>
        </Container>

        {showOTP && (
          <WalletOTP
            isOpen={showOTP}
            toggle={() => setShowOTP(false)} // Update toggle function
            getpaymentmethod={getpaymentmethod}
          />
        )}

        {/* {Withdraw && (
          <WalletWithdraw
            isOpen={Withdraw}
            toggle={() => setWithdraw(false)} // Update toggle function
            methodsdata={methodsdata as MethodData[]}     
                 />
        )} */}

        <ModalSetting
          isOpen={showSetting}
          toggle={() => setShowSetting(false)} // Keeps the toggle functionality working
          getpaymentmethod={getpaymentmethod}
          methodsdata={methodsdata as MethodData[]}
          currentId={currentId}
          setAction={setAction} // This will pass the state updater function
        />
      </div>
    </React.Fragment>
  );
};

export default Wallet;
