import { promoter_baseUrl } from "./const";
import {
  deletePromoterService,
  deleteService,
  getService,
  PostImage,
  postPromoter,
  postPromoterService,
  postService,
  putKYC,
} from "./http_service";
const PromoterBaseUrl = promoter_baseUrl;

export default function createPromoterClient(
  firstName: string,
  state: string,
  country: string,
  email: string,
  phone: string
) {
  let createAccountData = {
    firstName: firstName,
    lastName: "",
    gender: "",
    city: "",
    state: state,
    country: country,
    email: email,
    emailVerified: "Y",
    phone: phone,
    phoneVerified: "N",
    pincode: "",
  };

  return postPromoterService(
    PromoterBaseUrl + "/user",
    createAccountData,
    "createUserProfile"
  );
}

async function getApplicant() {
  return getService(PromoterBaseUrl + "/applicant", "getApplicant");
}

// get details of promoter

async function getProfile() {
  return getService(PromoterBaseUrl + "promoter", "getPromoter");
}

async function getDocType() {
  return getService(
    PromoterBaseUrl + "/settings/static/identitydocumenttypes",
    "getIdentityDocumentTypes"
  );
}
async function getRewardList( first,last) {
  return getService(
    PromoterBaseUrl + `/promoter/rewards?pageNum=${first}&pageSize=${last}&sortOrder=desc&keyword=&trnStatus=&claimStatus=&rewardType=`,
    "getAllPromoterReward"
  );
}

async function updatesProfile(
  firstName: string,
  lastName: string,
  // emailval: string,
  // emailVerified: string,
  // phoneVerified: string,
  pincode: string,
  // gender: string,
  country: string,
  state: string
) {
  let createAccountData = {
    firstName: firstName,
    lastName: lastName,

    // email: emailval,
    // emailVerified: emailVerified,
    // phoneVerified: phoneVerified,
    pincode: pincode,
    // gender: gender,
    country: country,
    state: state,
  };
  return postPromoter(
    PromoterBaseUrl + "promoter",
    createAccountData,
    "updatePromoter"
  );
}

async function addKyc(
  name: string,
  gender: string,
  DOB: Date,
  permanentPin: string,
  IdType: string,
  IdNumber: string
) {
  let createAccountData = {
    name: name,
    gender: gender,
    DOB: DOB,
    permanentPin: permanentPin,
    IdType: IdType,
    IdNumber: IdNumber,
    address: [],
  };
  return putKYC(
    PromoterBaseUrl + "promoter/kyc",
    createAccountData,
    "updatePromoterKYC"
  );
}

async function kycDoc(document: File) {
  // debugger
  const formData = new FormData();
  formData.append('file', document);
  //   let createAccountData = {
  //   document: formData,
  // };
  return PostImage(
    PromoterBaseUrl + "promoter/kyc/document",
    formData,
    "updatePromoterKYCDocument"
  );
}

async function addLeadGen(
  Phone: string,
  leadName: string,
  pincode: string,
  pocName: string,
  pocEmail: any,
  countryCode: string,
  countryName: string
) {
  // debugger
  let createAccountData = {
    channelID: "C1",
    channelName: "PromoterApp",
    programId: "P1",
    programName: "Promoter Program",
    leadCategoryId: "08",
    leadCategoryName: "Product Afiliation",
    leadUniqueIdType: "P",
    leadUniqueID: Phone,
    leadName: leadName,
    pincode: pincode,
    origin: "",
    additionalInfo: {
      pocName: pocName,
      pocPhone: "",
      pocEmail: pocEmail,
      address: {
        line1: "",
        line2: "",
        countryCode: countryCode,
        countryName: countryName,
      },
    },
  };
  return postPromoterService(
    PromoterBaseUrl + "promoter/leadgen",
    createAccountData,
    "createPromoterLeadGen"
  );
}

// async function getAllLeadGen(catId:string) {
//   return getService( PromoterBaseUrl+ "promoter/leadgen?pageNum=1&pageSize=5&sortOrder=desc&keyword=&category="+catId,
//      "getAllPromoterLead");
// }

async function getAllLeadGen(
  catId: string,
  leadStatus: string,
  searchValue: string
) {
  return getService(
    PromoterBaseUrl +
      "promoter/leadgen?pageNum=1&pageSize=10&sortOrder=desc&keyword=" +
      searchValue +
      "&category=" +
      catId +
      "&leadStatus=" +
      leadStatus,
    "getAllPromoterLead"
  );
}

async function getAction() {
  return getService(PromoterBaseUrl + "settings", "getPromoterSettings");
}

async function getTranscationSummary() {
  return getService(PromoterBaseUrl + "promoter/transactionsummary", "getAllPromoterTranscationLogSummary");
}

async function getTransaction(first,last) {
  return getService(
    PromoterBaseUrl + `promoter/transaction?pageNum=${first}&pageSize=${last}&sortOrder=desc&method=&status=&type=`,
     "getAllPromoterTranscationLog");
}




async function updateLeadStatus(
  leadId: string,
  leadStatusDesc: string,
  statusId: string
) {
  // debugger
  let createAccountData = {
    leadStatus: statusId,
    leadStatusDesc: leadStatusDesc,
  };
  return postPromoter(   
    PromoterBaseUrl + "promoterleads/" + leadId + "/status",
    createAccountData,
    "updatePromoterLeadStatusAdmin"
  );
}

async function addpaymethod(
  method: any,
  methodName: any,
  Type: any,
  RefNumber: any,
  RefId: any,
  IFSCCode: any,
) {
  // debugger
  let createAccountData = {
    status: "A",
    method: method,
    methodName: methodName,
    Type: Type,
    RefNumber: RefNumber,
    RefId: RefId,
    IFSCCode: IFSCCode,
    SWIFTCode: "",
  };
  return postPromoterService(
    PromoterBaseUrl + "/promoter/paymethod",
    createAccountData,
    "createPromoterPaymentMethod"
  );
}


async function getPromoterPaymentmethod() {
  return getService(PromoterBaseUrl + "promoter/paymethod?pageNum=1&pageSize=10&sortOrder=desc&method=&status","getAllPromoterPaymentMethod");
}

async function deletePromoterPaymentmethod(seqId:string) {
  return deletePromoterService(PromoterBaseUrl + `promoter/paymethod/${seqId}`,"deletePromoterPaymentMethod");
}

async function setPrimaryAccount(seqId:string) {
  let createAccountData = {
    status: 0,
   
  };

  return postPromoter(PromoterBaseUrl + `/promoter/paymethod/${seqId}/setasprimary`,createAccountData,"setDefaultPromoterPaymentMethod");
}




export {
  getApplicant,
  getProfile,
  updatesProfile,
  addKyc,
  getDocType,
  kycDoc,
  addLeadGen,
  getAllLeadGen,
  getAction,
  updateLeadStatus,
  getPromoterPaymentmethod,
  addpaymethod,
  deletePromoterPaymentmethod,
  setPrimaryAccount,
  getRewardList,
  getTranscationSummary,
  getTransaction
};
