// // import {
// //   ModalHeader,
// //   Modal,
// //   ModalBody,
// //   Form,
// //   Alert,
// //   Label,
// //   Input,
// //   FormFeedback,
// // } from "reactstrap";

// // import React, { useEffect, useState } from "react";

// // import * as Yup from "yup";
// // import { useFormik } from "formik";
// // import { useDispatch } from "react-redux";
// // import styles from "./message-modal.module.scss";
// // import createPromoterClient from "assistance/promoterClient_service";
// // import { removeToken } from "assistance/user_service";
// // import { logout } from "assistance/identity_service";
// // import { createSelector } from "reselect";
// // import { useSelector } from "react-redux";
// // import { fetchCountryList } from "pages/Authentication/Registerfunctions";
// // import { getStates } from "assistance/static_api";
// // interface ModalProps {
// //   message?: string;
// //   button1Text?: string;
// //   button2Text?: string;
// //   onButton1Click: () => void;
// //   onButton2Click: () => void;
// // }

// // interface Country {
// //   id: number;
// //   sortname: string;
// //   name: string;
// //   phoneCode: number;
// // }
// // interface State {
// //   id: number;
// //   name: string;
// //   country_id: number;
// // }
// // const ModalMess: React.FC<ModalProps> = ({
// //   message,
// //   button1Text,
// //   button2Text,
// //   onButton1Click,
// //   onButton2Click,
// // }) => {
// //   const [selectedCode, setSelectedCode] = useState("91");
// //   const [selectCode, setSelectCode] = useState("101");
// //   const [phoneNum, setPhoneNum] = useState("");
// //   const [countryList, setCountryList] = useState<Country[]>([]);
// //   const [loading, setLoading] = useState(true);
// //   const [userData, setUserData] = useState(null);
// //   const [showOTP, setShowOTP] = useState(false);
// //   const [show, setShow] = useState(false);
// //   const [data, setData] = useState([""]);
// //   const [stateList, setStateList] = useState<State[]>([]);
// //   const [selectedState, setSelectedState] = useState("");
// //   const [selectedCountry, setSelectedCountry] = useState("IN");
// //   const [selectedRegion, setSelectedRegion] = useState("");

// //   const [formData, setFormData] = useState({
// //     email: "",
// //     username: "",
// //     password: "",
// //     state: "",
// //     type: "",
// //     phone: "",
// //     country: "India",
// //   });
// //   const [selectedCountryDetail, setSelectedCountryDetail] = useState({
// //     id: "101",
// //     code: "IN",
// //     name: "India",
// //   });

// //   const dispatch = useDispatch<any>();

// //   useEffect(() => {
// //     // debugger
// //     // Get the 'fromData' query parameter from the URL
// //     const urlParams = new URLSearchParams(window.location.search);
// //     const fromDataParam = urlParams.get("email");

// //     console.log(fromDataParam);

// //     if (fromDataParam) {
// //       setFormData((prevFormData) => ({
// //         ...prevFormData,
// //         email: fromDataParam,
// //       }));
// //     }

// //     const cachedCountries = localStorage.getItem("countryList");
// //     if (cachedCountries) {
// //       setCountryList(JSON.parse(cachedCountries));
// //       setLoading(false);
// //     } else {
// //       const getCountries = async () => {
// //         try {
// //           const countries = await fetchCountryList();
// //           setCountryList(countries);
// //           localStorage.setItem("countryList", JSON.stringify(countries));
// //         } catch (err) {
// //           console.error("Error fetching countries:", err);
// //         } finally {
// //           setLoading(false);
// //         }
// //       };
// //       getCountries();
// //     }
// //   }, []);

// //   useEffect(() => {
// //     if (selectCode) {
// //       // Check if states for the selected country are already cached
// //       const cachedStates = sessionStorage.getItem(`states_${selectCode}`);
// //       if (cachedStates) {
// //         setStateList(JSON.parse(cachedStates)); // Use cached states
// //       } else {
// //         const getState = async (selectCode: any) => {
// //           try {
// //             const data = await getStates(selectCode); // Call API for states based on country selectedCode
// //             setStateList(data?.data?.data);
// //             sessionStorage.setItem(
// //               `states_${selectCode}`,
// //               JSON.stringify(data?.data?.data)
// //             ); // Cache states for the selected country
// //           } catch (err) {
// //             console.error("Error fetching states:", err);
// //           } finally {
// //             setLoading(false);
// //           }
// //         };
// //         getState(selectCode);
// //       }
// //     }
// //   }, [selectCode]);

// //   const handleSelectChange = (e) => {
// //     setSelectedRegion(e.target.value);
// //   };

// //   const validation = useFormik({
// //     enableReinitialize: true,
// //     initialValues: {
// //       email: "",
// //       username: "",
// //       password: "",
// //       type: "",
// //       phone: "",
// //       country: formData.country || "", // Bind initialValues to formData
// //     },
    
// //     validationSchema: Yup.object({
// //       email: Yup.string()
// //         .email("Invalid email format")
// //         .required("Please Enter Your Email"),
// //       username: Yup.string()
// //         .matches(/^[A-Za-z\s]/, "Username must contain only letters")
// //         .min(3, "Username must be at least 3 characters long")
// //         .required("Please Enter Your Username"),
// //       // password: Yup.string()
// //       //   .min(8, "Password must be at least 8 characters long")
// //       //   .required("Please Enter Your Password"),

// //       type: Yup.string().required("Please Choose a type"),
// //       phone: Yup.string()
// //         .matches(/^[0-9]+$/, "Phone number must contain only digits")
// //         .min(10, "Phone number must be at least 10 digits")
// //         .max(10, "Phone number max 10 digits")
// //         .required("Please Enter Your Phone number"),
// //       country: Yup.string().required("Please Select a Country"),
// //     }),


// //     onSubmit: (values) => {
// //       debugger;
// //       const mail = sessionStorage.getItem("emailID");

// //       const combinedPhone = `${selectedCode}-${values.phone}`;
// //       const finalData = {
// //         ...values,
// //         phone: combinedPhone,
// //       };
// //       console.log(finalData);

// //       setFormData({
// //         username: finalData?.username as string,
// //         email: mail as string,
// //         phone: "+" + finalData?.phone,
// //         state: selectedState,
// //         country: finalData?.country,
// //         password: finalData.password, // Corrected this
// //         type: finalData?.type,
// //       });
// //       debugger;

// //       createPartner(finalData)
// //         .then((result) => {
// //           // console.log(result.message);
// //         })
// //         .catch((error) => {
// //           console.error("Verification failed", error);
// //         });
// //     },
// //   });

// //   async function createPartner(finalData: {
// //     phone: any;
// //     email: any;
// //     username: any;
// //     password?: string;
// //     type?: string;
// //     country: any;
// //     state?: any;
// //   }) {
// //     if (finalData) {
// //       debugger;
// //       const mail = sessionStorage.getItem("emailID");

// //       const firstName = finalData?.username;
// //       const emailval = mail as string;
// //       const phoneNumber = finalData?.phone; // Corrected from phonee
// //       const state = selectedRegion ? selectedRegion : finalData?.state;
// //       const country = finalData?.country;
// //       debugger;

// //       createPromoterClient(
// //         firstName,
// //         state,
// //         country,
// //         emailval,
// //         phoneNumber
// //       ).then((response) => {
// //         if (response.status === 200) {
// //           // router.push("/dashboard");
// //           window.location.href = "/dashboard";
// //         } else if (response.status === 202) {
// //           window.location.href = "/dashboard";
// //         } else {
// //           // Handle error
// //         }
// //       });
// //     }
// //   }

// //   const handleInputChange = (e) => {
// //     const { name, value } = e.target;
// //     if (name === "phone") {
// //       setPhoneNum(value);
// //     }
// //     setFormData((prevData) => ({
// //       ...prevData,
// //       [name]: value,
// //     }));
// //     validation.setFieldValue(name, value); // Update Formik field value
// //   };

// //   const handleLogout = () => {
// //     // Add your logout logic here, e.g., clearing tokens, redirecting, etc.
// //     console.log("Logging out...");

// //     // logout()
// //     logout().then((response) => {
// //       if (response.data.status === 200) {
// //         removeToken();
// //         // setAuthenticated(false);
// //         // toast.success("Logout Success");
// //         sessionStorage.removeItem("token");

// //         let cookieName = "token";
// //         document.cookie =
// //           cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

// //         let cookieNameClient = "clientToken";
// //         document.cookie =
// //           cookieNameClient +
// //           "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

// //         setTimeout(() => {
// //           window.location.href = "https://insider.skartio.com/";
// //         }, 1000);
// //       }
// //     });
// //   };

// //   const selectProperties = createSelector(
// //     (state: any) => state.Account,
// //     (account) => ({
// //       user: account.user,
// //       registrationError: account.registrationError,
// //       loading: account.loading,
// //     })
// //   );

// //   const { user, registrationError } = useSelector(selectProperties);

// //   const handleCodeChange = (e) => {
// //     setSelectedCode(e.target.value);
// //   };

// //   // Handle country selection and update phone code
// //   const handleCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
// //     const selectedCountry = e.target.value;
// //     handleInputChange(e); // Keep this to update form data

// //     // Ensure phoneCode is converted to a string
// //     const country = countryList.find((c) => c.name === selectedCountry);
// //     const phoneCodeString = country?.phoneCode.toString() || "";
// //     const codeString = country?.id.toString() || "";

// //     setSelectedCode(phoneCodeString);
// //     setSelectCode(codeString);
// //     // Dynamically set country details
// //     // Dynamically set country details
// //     setSelectedCountryDetail({
// //       id: country?.id?.toString() || "", // Convert id to string if it exists, otherwise use an empty string
// //       code: country?.phoneCode?.toString() || "", // Convert phoneCode to string if it exists, otherwise use an empty string
// //       name: country?.name || "",
// //     });

// //     setFormData((prevFormData) => ({
// //       ...prevFormData,
// //       country: selectedCountry,
// //     }));

// //     console.log(phoneCodeString); // Updated to log the local variable directly
// //   };

// //   return (
// //     <Modal isOpen={true}>
// //       <ModalBody>
// //         {/* <form className="needs-validation"> */}
// //         <div className="px-4 text-center modal-body">
// //           <p className="text-muted font-size-16 mb-4">
// //             To continue please Fill the Details
// //           </p>

// //           <Form className="form-horizontal" onSubmit={validation.handleSubmit}>
// //             {registrationError && (
// //               <Alert color="danger">{registrationError}</Alert>
// //             )}
// //             <div className="mb-3">
// //               <Label className="form-label  d-flex justify-content-start">
// //                 Name
// //               </Label>
// //               <Input
// //                 name="username"
// //                 type="text"
// //                 autoComplete="off"
// //                 placeholder="Enter username"
// //                 onChange={handleInputChange}
// //                 onBlur={validation.handleBlur}
// //                 value={formData.username || ""}
// //                 invalid={
// //                   validation.touched.username && validation.errors.username
// //                     ? true
// //                     : false
// //                 }
// //               />
// //               {validation.touched.username && validation.errors.username && (
// //                 <FormFeedback type="invalid">
// //                   {validation.errors.username}
// //                 </FormFeedback>
// //               )}
// //             </div>
// //             {/* <div className="mb-3">
// //                         <Label className="form-label d-flex justify-content-start">Email</Label>
// //                         <Input
// //                           id="email"
// //                           name="email"
// //                           autoComplete="off"
// //                           className="form-control"
// //                           placeholder="Enter email"
// //                           type="email"
// //                           onChange={handleInputChange}
// //                           onBlur={validation.handleBlur}
// //                           value={formData.email || ""}
// //                           invalid={
// //                             validation.touched.email && validation.errors.email
// //                               ? true
// //                               : false
// //                           }
// //                         />
// //                         {validation.touched.email &&
// //                           validation.errors.email && (
// //                             <FormFeedback type="invalid">
// //                               {validation.errors.email}
// //                             </FormFeedback>
// //                           )}
// //                       </div> */}
// //             <div className="mb-3">
// //               <label className="d-block mb-3 form-label d-flex justify-content-start">
// //                 Register Type:
// //               </label>
// //               <div className="form-check form-check-inline">
// //                 <Input
// //                   id="customRadioInline1"
// //                   name="type"
// //                   type="radio"
// //                   className="form-check-input"
// //                   value="Affiliate"
// //                   onChange={handleInputChange}
// //                 />
// //                 <Label className="form-check-label">Affiliate</Label>
// //               </div>
// //               <div className="form-check form-check-inline">
// //                 <Input
// //                   id="customRadioInline2"
// //                   name="type"
// //                   type="radio"
// //                   className="form-check-input"
// //                   value="Agency"
// //                   onChange={handleInputChange}
// //                 />
// //                 <Label className="form-check-label">Agency</Label>
// //               </div>
// //               <div className="form-check form-check-inline">
// //                 <Input
// //                   id="customRadioInline3"
// //                   name="type"
// //                   type="radio"
// //                   className="form-check-input"
// //                   value="Developer"
// //                   onChange={handleInputChange}
// //                   disabled={true} // This will disable the radio button
// //                 />
// //                 <Label className="form-check-label">Developer</Label>
// //               </div>
// //               {validation.touched.type && validation.errors.type && (
// //                 <div className="text-danger">{validation.errors.type}</div>
// //               )}
// //             </div>
// //             <div className="mb-3">
// //               <label className="form-label d-flex justify-content-start">
// //                 Current Country
// //               </label>
// //               <select
// //                 className={`form-select ${
// //                   validation.touched.country && validation.errors.country
// //                     ? "is-invalid"
// //                     : ""
// //                 }`}
// //                 name="country"
// //                 onChange={handleCountryChange} // Make sure this function is used here
// //                 value={formData.country || ""}
// //               >
// //                 <option value="">Select Country</option>
// //                 {countryList &&
// //                   countryList?.map((country, index) => (
// //                     <option key={index} value={country.name}>
// //                       {country.name}
// //                     </option>
// //                   ))}
// //               </select>

// //               {validation.touched.country && validation.errors.country && (
// //                 <div className="invalid-feedback">
// //                   {validation.errors.country}
// //                 </div>
// //               )}
// //             </div>
// //             <div className="mb-3">
// //               <label className="form-label d-flex justify-content-start">
// //                 Current State
// //               </label>

// //               <select
// //                 id="region"
// //                 className={`form-select ${
// //                   validation.touched.country && validation.errors.country
// //                     ? "is-invalid"
// //                     : ""
// //                 }`}
// //                 value={selectedRegion}
// //                 onChange={handleSelectChange}
// //               >
// //                 <option value="">Select a State</option>
// //                 {stateList?.map((region) => (
// //                   <option key={region.id} value={region.name}>
// //                     {region.name}
// //                   </option>
// //                 ))}
// //               </select>

// //               {/* {validation.touched.country &&
// //                           validation.errors.country && (
// //                             <div className="invalid-feedback">
// //                               {validation.errors.country}
// //                             </div>
// //                           )} */}
// //             </div>

// //             <div className="mb-3">
// //               <label className="form-label d-flex justify-content-start">
// //                 Phone
// //               </label>
// //               <div className="input-group">
// //                 <select
// //                   className="form-select"
// //                   value={selectedCode}
// //                   onChange={handleCodeChange} // Ensure this is being handled properly
// //                   style={{ maxWidth: "70px" }}
// //                 >
// //                   {countryList &&
// //                     countryList?.map((country, index) => (
// //                       <option key={index} value={country.phoneCode.toString()}>
// //                         {country.phoneCode}
// //                       </option>
// //                     ))}
// //                 </select>
// //                 <Input
// //                   className="form-control"
// //                   type="tel"
// //                   name="phone"
// //                   autoComplete="off"
// //                   value={phoneNum || ""}
// //                   onChange={handleInputChange}
// //                   onBlur={validation.handleBlur}
// //                   placeholder="Enter phone number"
// //                   invalid={
// //                     validation.touched.phone && validation.errors.phone
// //                       ? true
// //                       : false
// //                   }
// //                 />
// //               </div>
// //               {validation.touched.phone && validation.errors.phone && (
// //                 <FormFeedback type="invalid">
// //                   {validation.errors.phone}
// //                 </FormFeedback>
// //               )}
// //             </div>

// //             <div className="mt-4">
// //               {/* <button
// //                           className="btn text-white w-100"
// //                           style={{ backgroundColor: "#642e5c" }}
// //                           type="submit"
// //                         >
// //                           Create Account
// //                         </button> */}
// //             </div>
// //             <div className="hstack gap-2 justify-content-center mb-0">
// //               <button
// //                 type="submit"
// //                 className="px-4 py-2 bg-blue-500  rounded hover:bg-blue-600 transition"
// //                 style={{
// //                   backgroundColor: "#642e5c",
// //                   // borderColor: "#642e5c",
// //                   border: "2px solid #642e5c",
// //                   color: "white",
// //                 }}
// //               >
// //                 {button1Text}
// //               </button>
// //               <button
// //                 onClick={onButton2Click}
// //                 className="px-4 py-2 bg-gray-500  rounded hover:bg-gray-600 transition"
// //                 style={{
// //                   backgroundColor: "#642e5c",
// //                   // borderColor: "#642e5c",
// //                   border: "2px solid #642e5c",
// //                   color: "white",
// //                 }}
// //               >
// //                 {button2Text}
// //               </button>
// //             </div>
// //           </Form>
// //         </div>
// //         {/* </form> */}
// //       </ModalBody>
// //     </Modal>
// //   );
// // };

// // export default ModalMess;



// import { useFormik } from "formik";
// import { useDispatch } from "react-redux";
// import styles from "./message-modal.module.scss";
// import createPromoterClient from "assistance/promoterClient_service";
// import { removeToken } from "assistance/user_service";
// import { logout } from "assistance/identity_service";
// import { createSelector } from "reselect";
// import { useSelector } from "react-redux";
// import { fetchCountryList } from "pages/Authentication/Registerfunctions";
// import { getStates } from "assistance/static_api";

// import React, { useState, useEffect } from 'react';
// import {
//   Button,
//   Modal,
//   ModalHeader,
//   ModalBody,
//   ModalFooter,
//   FormGroup,
//   Label,
//   Input
// } from 'reactstrap';
// import { Formik, Form, Field, ErrorMessage } from 'formik';
// import * as Yup from 'yup';
// interface ModalProps {
//   message?: string;
//   button1Text?: string;
//   button2Text?: string;
//   onButton1Click: () => void;
//   onButton2Click: () => void;
// }

// interface Country {
//   id: number;
//   sortname: string;
//   name: string;
//   phoneCode: number;
// }
// interface State {
//   id: number;
//   name: string;
//   country_id: number;
// }
// const ModalMess: React.FC<ModalProps> = ({
//   message,
//   button1Text,
//   button2Text,
//   onButton1Click,
//   onButton2Click,
//  }) => {
//   const [countries, setCountries] = useState([]);
//   const [states, setStates] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [selectedCountryDetail, setSelectedCountryDetail] = useState(null);

//   useEffect(() => {
//     const fetchCountryData = async () => {
//       try {
//         setLoading(true);
//         const countryData = await fetchCountryList();
//         setCountries(countryData);
//         setLoading(false);
//       } catch (error) {
//         console.error('Error fetching countries:', error);
//         setLoading(false);
//       }
//     };

//     fetchCountryData();
//   }, []);

//   useEffect(() => {
//     const fetchStateData = async () => {
//       if (selectedCountryDetail) {
//         try {
//           setLoading(true);
//           const stateData = await getStates(selectedCountryDetail.code);
//           setStates(stateData);
//           setLoading(false);
//         } catch (error) {
//           console.error('Error fetching states:', error);
//           setLoading(false);
//         }
//       }
//     };

//     fetchStateData();
//   }, [selectedCountryDetail]);

//   const validationSchema = Yup.object().shape({
//     firstName: Yup.string().required('First Name is required'),
//     lastName: Yup.string().required('Last Name is required'),
//     email: Yup.string().email('Invalid email format').required('Email is required'),
//     country: Yup.string().required('Country is required'),
//     state: Yup.string().required('State is required')
//   });

//   const handleFormSubmit = async (values, { resetForm }) => {
//     try {
//       setLoading(true);
//       await createPartner(values);
//       setLoading(false);
//       resetForm();
//       toggle(); // Close modal after success
//     } catch (error) {
//       console.error('Error creating partner:', error);
//       setLoading(false);
//     }
//   };

//   return (
//     <Modal isOpen={isOpen} toggle={toggle}>
//       <ModalHeader toggle={toggle}>Create Partner</ModalHeader>
//       <Formik
//         initialValues={{
//           firstName: '',
//           lastName: '',
//           email: '',
//           country: '',
//           state: ''
//         }}
//         validationSchema={validationSchema}
//         onSubmit={handleFormSubmit}
//       >
//         {({ values, handleChange, setFieldValue }) => (
//           <Form>
//             <ModalBody>
//               <FormGroup>
//                 <Label for="firstName">First Name</Label>
//                 <Field
//                   type="text"
//                   name="firstName"
//                   id="firstName"
//                   className="form-control"
//                 />
//                 <ErrorMessage
//                   name="firstName"
//                   component="div"
//                   className="text-danger"
//                 />
//               </FormGroup>

//               <FormGroup>
//                 <Label for="lastName">Last Name</Label>
//                 <Field
//                   type="text"
//                   name="lastName"
//                   id="lastName"
//                   className="form-control"
//                 />
//                 <ErrorMessage
//                   name="lastName"
//                   component="div"
//                   className="text-danger"
//                 />
//               </FormGroup>

//               <FormGroup>
//                 <Label for="email">Email</Label>
//                 <Field
//                   type="email"
//                   name="email"
//                   id="email"
//                   className="form-control"
//                 />
//                 <ErrorMessage
//                   name="email"
//                   component="div"
//                   className="text-danger"
//                 />
//               </FormGroup>

//               <FormGroup>
//                 <Label for="country">Country</Label>
//                 <Input
//                   type="select"
//                   name="country"
//                   id="country"
//                   value={values.country}
//                   onChange={(e) => {
//                     handleChange(e);
//                     const selectedCountry = countries.find(
//                       (c) => c.code === e.target.value
//                     );
//                     setSelectedCountryDetail(selectedCountry);
//                     setFieldValue('state', ''); // Reset state when country changes
//                   }}
//                 >
//                   <option value="">Select Country</option>
//                   {countries.map((country) => (
//                     <option key={country.code} value={country.code}>
//                       {country.name}
//                     </option>
//                   ))}
//                 </Input>
//                 <ErrorMessage
//                   name="country"
//                   component="div"
//                   className="text-danger"
//                 />
//               </FormGroup>

//               <FormGroup>
//                 <Label for="state">State</Label>
//                 <Input
//                   type="select"
//                   name="state"
//                   id="state"
//                   value={values.state}
//                   onChange={handleChange}
//                   disabled={!values.country}
//                 >
//                   <option value="">Select State</option>
//                   {states.map((state) => (
//                     <option key={state.code} value={state.code}>
//                       {state.name}
//                     </option>
//                   ))}
//                 </Input>
//                 <ErrorMessage
//                   name="state"
//                   component="div"
//                   className="text-danger"
//                 />
//               </FormGroup>
//             </ModalBody>

//             <ModalFooter>
//               <Button type="submit" color="primary" disabled={loading}>
//                 {loading ? 'Submitting...' : 'Submit'}
//               </Button>{' '}
//               <Button color="secondary" onClick={toggle} disabled={loading}>
//                 Cancel
//               </Button>
//             </ModalFooter>
//           </Form>
//         )}
//       </Formik>
//     </Modal>
//   );
// };

// export default ModalMess;




import React, { useEffect, useState } from 'react';
import { ModalHeader, Modal, ModalBody } from 'reactstrap';
import styles from './message-modal.module.scss'
import {
    Row,
    Col,
    CardBody,
    Card,
    Alert,
    Container,
    Input,
    Label,
    Form,
    FormFeedback,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import profileImg from "../../assets/images/profile-img.png";
// import logoImg from "../../assets/images/logo.svg";
import { createSelector } from "reselect";
import { apiError } from "../../slices/thunk";
import OTPModal from "Components/partner-register-modal/partner-register-modal";
import { getStates } from "assistance/static_api";
import createPromoterClient, { getProfile } from "assistance/promoterClient_service";
import { logout } from "assistance/identity_service";
import { removeToken } from "assistance/user_service";
import { fetchCountryList } from 'pages/Authentication/Registerfunctions';

interface ModalProps {
    message?: string;
    button1Text?: string;
    button2Text?: string;
    isOpen: boolean;
    toggle: () => void;
    // onButton1Click: () => void;
    // onButton2Click: () => void;
}



interface Country {
    id: number;
    sortname: string;
    name: string;
    phoneCode: number;
}
interface State {
    id: number;
    name: string;
    country_id: number;
}

const ModalMess: React.FC<ModalProps> = ({
    message,
    button1Text,
    button2Text,
    isOpen,
    toggle,
    // onButton1Click,
    // onButton2Click,
}) => {



    const [selectedCode, setSelectedCode] = useState("91");
    const [selectCode, setSelectCode] = useState("101");
    const [phoneNum, setPhoneNum] = useState("");
    const [countryList, setCountryList] = useState<Country[]>([]);
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState(null);
    const [showOTP, setShowOTP] = useState(false);
    const [show, setShow] = useState(false);
    const [data, setData] = useState([""]);
    const [stateList, setStateList] = useState<State[]>([]);
    const [selectedState, setSelectedState] = useState("");
    const [selectedCountry, setSelectedCountry] = useState("IN");
    const [selectedRegion, setSelectedRegion] = useState("");

    const handleSelectChange = (e) => {
        setSelectedRegion(e.target.value);
    };
    const [formData, setFormData] = useState({
        email: "",
        username: "",
        password: "",
        state: "",
        type: "",
        phone: "",
        country: "India",
    });
    const [selectedCountryDetail, setSelectedCountryDetail] = useState({
        id: "101",
        code: "IN",
        name: "India",
    });




    
    useEffect(() => {
        // debugger
        // Get the 'fromData' query parameter from the URL
        const urlParams = new URLSearchParams(window.location.search);
        const fromDataParam = urlParams.get("email");

        // console.log(fromDataParam);

        if (fromDataParam) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                email: fromDataParam,
            }));
        }

        const cachedCountries = localStorage.getItem("countryList");
        if (cachedCountries) {
            setCountryList(JSON.parse(cachedCountries));
            setLoading(false);
        } else {
            const getCountries = async () => {
                try {
                    const countries = await fetchCountryList();
                    setCountryList(countries);
                    localStorage.setItem("countryList", JSON.stringify(countries));
                } catch (err) {
                    console.error("Error fetching countries:", err);
                } finally {
                    setLoading(false);
                }
            };
            getCountries();
        }
    }, []);

    useEffect(() => {
        if (selectCode) {
            // Check if states for the selected country are already cached
            const cachedStates = sessionStorage.getItem(`states_${selectCode}`);
            if (cachedStates) {
                setStateList(JSON.parse(cachedStates)); // Use cached states
            } else {
                const getState = async (selectCode: any) => {
                    try {
                        const data = await getStates(selectCode); // Call API for states based on country selectedCode
                        setStateList(data?.data?.data);
                        sessionStorage.setItem(
                            `states_${selectCode}`,
                            JSON.stringify(data?.data?.data)
                        ); // Cache states for the selected country
                    } catch (err) {
                        console.error("Error fetching states:", err);
                    } finally {
                        setLoading(false);
                    }
                };
                getState(selectCode);
            }
        }
    }, [selectCode]);

 

    const handleCodeChange = (e) => {
        setSelectedCode(e.target.value);
    };

    // Handle country selection and update phone code
    const handleCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedCountry = e.target.value;
        handleInputChange(e); // Keep this to update form data

        // Ensure phoneCode is converted to a string
        const country = countryList.find((c) => c.name === selectedCountry);
        const phoneCodeString = country?.phoneCode.toString() || "";
        const codeString = country?.id.toString() || "";

        setSelectedCode(phoneCodeString);
        setSelectCode(codeString);
        // Dynamically set country details
        // Dynamically set country details
        setSelectedCountryDetail({
            id: country?.id?.toString() || "", // Convert id to string if it exists, otherwise use an empty string
            code: country?.phoneCode?.toString() || "", // Convert phoneCode to string if it exists, otherwise use an empty string
            name: country?.name || "",
        });

        setFormData((prevFormData) => ({
            ...prevFormData,
            country: selectedCountry,
        }));

        // console.log(phoneCodeString); // Updated to log the local variable directly
    };

    const dispatch = useDispatch<any>();

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: "",
            username: "",
            password: "",
            type: "",
            phone: "",
            country: formData.country || "", // Bind initialValues to formData
        },

        validationSchema: Yup.object({
            username: Yup.string()
                .matches(/^[A-Za-z\s]/, "Username must contain only letters")
                .min(3, "Username must be at least 3 characters long")
                .required("Please Enter Your Username"),
            // password: Yup.string()
            //   .min(8, "Password must be at least 8 characters long")
            //   .required("Please Enter Your Password"),

            type: Yup.string().required("Please Choose a type"),
            phone: Yup.string()
                .matches(/^[0-9]+$/, "Phone number must contain only digits")
                .min(10, "Phone number must be at least 10 digits")
                .max(10, "Phone number max 10 digits")
                .required("Please Enter Your Phone number"),
            country: Yup.string().required("Please Select a Country"),
        }),

        onSubmit: (values) => {
            // console.log("hi", values)
            const combinedPhone = `${selectedCode}-${values.phone}`;
            const finalData = {
                ...values,
                phone: combinedPhone,
            };
            const data = sessionStorage.getItem('emailID')

            setFormData({
                username: finalData?.username,
                email: data as string,
                phone:  finalData?.phone,
                state: selectedState,
                country: finalData?.country,
                password: finalData.password, // Corrected this
                type: finalData?.type,
            });
            


            createPartner(finalData)
                .then((result) => {
                    // console.log(result.message);
                })
                .catch((error) => {
                    console.error("Verification failed", error);
                });
        },
    });

    async function createPartner(finalData: { phone: any; email: any; username: any; password?: string; type?: string; country: any; state?: any; }) {
        const data = sessionStorage.getItem('emailID')
        if (finalData) {
            const firstName = finalData?.username;
            const emailval = data;
            const phoneNumber = finalData?.phone; // Corrected from phonee
            const state = selectedRegion ? selectedRegion : finalData?.state;
            const country = finalData?.country;
            createPromoterClient(
                firstName,
                state,
                country,
                emailval as string,
                phoneNumber
            ).then((response) => {
                if (response.status === 200) {
                    // router.push("/dashboard");
                    fetchProfile()
                    toggle()

                    // window.location.href = "/dashboard";
                } else if (response.status === 202) {
                    // window.location.href = "/dashboard";
                } else {
                    // Handle error
                }
            });
        }
    }
    const selectProperties = createSelector(
        (state: any) => state.Account,
        (account) => ({
            user: account.user,
            registrationError: account.registrationError,
            loading: account.loading,
        })
    );

    const { user, registrationError } = useSelector(selectProperties);

    useEffect(() => {
        dispatch(apiError());
    }, [dispatch]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === "phone") {
            setPhoneNum(value);
        }
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        validation.setFieldValue(name, value); // Update Formik field value
    };

    const handleLogout = () => {
        // Add your logout logic here, e.g., clearing tokens, redirecting, etc.

        // logout()
        logout().then((response) => {
            if (response.data.status === 200) {
                removeToken();
                // setAuthenticated(false);
                // toast.success("Logout Success");
                sessionStorage.removeItem("token");

                let cookieName = "token";
                document.cookie =
                    cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

                let cookieNameClient = "clientToken";
                document.cookie =
                    cookieNameClient +
                    "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

                setTimeout(() => {
                    window.location.href = "https://insider.skartio.com/";
                }, 1000);
            }
        });
    };

    const fetchProfile = async () => {
        try {
          const response = await getProfile();
  
          if (response.data && response.status === 200) {
            // console.log(response.data?.data?.firstName);
            // setProfileDat(response.data?.data);
          } else if (response.data && response.status === 203) {
            setShow(true)
  
  
          }
  
  
        } catch (error) {
          console.error('Failed to fetch profile data', error);
        }
      };

    return (

        <Modal

        isOpen={isOpen} toggle={toggle}



        >
            <ModalBody>
                    <div className="px-4 text-center modal-body">

                        <p className="text-muted font-size-16 mb-4">
                            To continue please complete the Profile Creation Process
                        </p>


                        <Form
                            className="form-horizontal"
                            onSubmit={validation.handleSubmit}
                        >
                            {registrationError && (
                                <Alert color="danger">{registrationError}</Alert>
                            )}
                            <div className="mb-3">
                                <Label  className="form-label d-flex justify-content-start">Name</Label>
                                <Input
                                    name="username"
                                    type="text"
                                    autoComplete="off"
                                    placeholder="Enter username"
                                    onChange={handleInputChange}
                                    onBlur={validation.handleBlur}
                                    value={formData.username || ""}
                                    invalid={
                                        validation.touched.username &&
                                            validation.errors.username
                                            ? true
                                            : false
                                    }
                                />
                                {validation.touched.username &&
                                    validation.errors.username && (
                                        <FormFeedback type="invalid">
                                            {validation.errors.username}
                                        </FormFeedback>
                                    )}
                            </div>

                            <div className="mb-3">
                                <label className="form-label d-block mb-3 d-flex justify-content-start">
                                    Register Type:
                                </label>

                                <div className='d-flex justify-content-start mb-2'>
                                <div className="form-check form-check-inline ">
                                    <Input
                                        id="customRadioInline1"
                                        name="type"
                                        type="radio"
                                        
                                        className="form-check-input"
                                        value="Affiliate"
                                        onChange={handleInputChange}
                                    />
                                    <Label className="form-check-label ">Individual</Label>
                                </div>
                                <div className="form-check form-check-inline ">
                                    <Input
                                        id="customRadioInline2"
                                        name="type"
                                        type="radio"
                                        className="form-check-input"
                                        value="Agency"
                                        onChange={handleInputChange}
                                    />
                                    <Label className="form-check-label">Business</Label>
                                </div>
                                {/* <div className="form-check form-check-inline">
                                    <Input
                                        id="customRadioInline3"
                                        name="type"
                                        type="radio"
                                        className="form-check-input"
                                        value="Developer"
                                        onChange={handleInputChange}
                                        disabled={true} // This will disable the radio button
                                    />
                                    <Label className="form-check-label">Developer</Label>
                                </div> */}

</div>

                                {validation.touched.type && validation.errors.type && (
                                    <div className="text-danger">
                                        {validation.errors.type}
                                    </div>
                                )}
                            </div>
                            <div className="mb-3">
                                <label  className="form-label d-flex justify-content-start">Current Country</label>
                                <select
                                    className={`form-select ${validation.touched.country &&
                                            validation.errors.country
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                    name="country"
                                    onChange={handleCountryChange} // Make sure this function is used here
                                    value={formData.country || ""}
                                >
                                    <option value="">Select Country</option>
                                    {countryList &&
                                        countryList?.map((country, index) => (
                                            <option key={index} value={country.name}>
                                                {country.name}
                                            </option>
                                        ))}
                                </select>

                                {validation.touched.country &&
                                    validation.errors.country && (
                                        <div className="invalid-feedback">
                                            {validation.errors.country}
                                        </div>
                                    )}
                            </div>
                            <div className="mb-3">
                                <label  className="form-label d-flex justify-content-start">Current State</label>

                                <select
                                    id="region"
                                    className={`form-select ${validation.touched.country &&
                                            validation.errors.country
                                            ? "is-invalid"
                                            : ""
                                        }`}
                                    value={selectedRegion}
                                    onChange={handleSelectChange}
                                >
                                    <option value="">Select a State</option>
                                    {stateList?.map((region) => (
                                        <option key={region.id} value={region.name}>
                                            {region.name}
                                        </option>
                                    ))}
                                </select>

                                {/* {validation.touched.country &&
                          validation.errors.country && (
                            <div className="invalid-feedback">
                              {validation.errors.country}
                            </div>
                          )} */}
                            </div>

                            <div className="mb-3">
                                <label  className="form-label d-flex justify-content-start">Phone</label>
                                <div className="input-group">
                                    <select
                                        className="form-select"
                                        value={selectedCode}
                                        onChange={handleCodeChange} // Ensure this is being handled properly
                                        style={{ maxWidth: "70px" }}
                                    >
                                        {countryList &&
                                            countryList?.map((country, index) => (
                                                <option
                                                    key={index}
                                                    value={country.phoneCode.toString()}
                                                >
                                                    {country.phoneCode}
                                                </option>
                                            ))}
                                    </select>
                                    <Input
                                        className="form-control"
                                        type="tel"
                                        name="phone"
                                        autoComplete="off"
                                        value={phoneNum || ""}
                                        onChange={handleInputChange}
                                        onBlur={validation.handleBlur}
                                        placeholder="Enter phone number"
                                        invalid={
                                            validation.touched.phone &&
                                                validation.errors.phone
                                                ? true
                                                : false
                                        }
                                    />
                                </div>
                                {validation.touched.phone &&
                                    validation.errors.phone && (
                                        <FormFeedback type="invalid">
                                            {validation.errors.phone}
                                        </FormFeedback>
                                    )}
                            </div>

                            <div className="mt-4">
                                <button
                                    className="btn text-white w-100"
                                    style={{ backgroundColor: "#642e5c" }}
                                    type="submit"
                                >
                                    Submit 
                                </button>
                            </div>
                        </Form>
                        {/* <div className="hstack gap-2 justify-content-center mb-0">
                            <button
                                onClick={onButton1Click}
                                className="px-4 py-2 bg-blue-500  rounded hover:bg-blue-600 transition"
                                style={{
                                    backgroundColor: "#642e5c",
                                    // borderColor: "#642e5c",
                                    border: "2px solid #642e5c",
                                    color: "white",
                                  }}
                            >
                                {button1Text}
                            </button>
                            <button
                                onClick={onButton2Click}
                                className="px-4 py-2 bg-gray-500  rounded hover:bg-gray-600 transition"
                                style={{
                                    backgroundColor: "#642e5c",
                                    // borderColor: "#642e5c",
                                    border: "2px solid #642e5c",
                                    color: "white",
                                  }}
                            >
                                {button2Text}
                            </button>
                        </div> */}
                    </div>
            </ModalBody>
        </Modal>

    );
};

export default ModalMess;

