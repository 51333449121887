

import React, { useEffect, useState } from "react";
import { Modal, ModalBody, Button, Nav, NavItem, NavLink } from "reactstrap";
import styles from "./wallet-modal.module.scss";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import classnames from "classnames";
import { addpaymethod, deletePromoterPaymentmethod, setPrimaryAccount } from "assistance/promoterClient_service";

interface MethodData {
    IFSCCode: string;
    IsPrimary: boolean;
    RefId: string;
    RefNumber: string;
    SWIFTCode: string;
    Type: string;
    method: string;
    methodName: string;
    seq: number;
    status: string;
  }

type ModalSettingProps = {
    isOpen: boolean;
    toggle: () => void;
    getpaymentmethod: () => void;
    methodsdata: MethodData[]; // Change this to accept MethodData[]
    currentId:any
    setAction: React.Dispatch<React.SetStateAction<boolean>>;
  };
  

  const ModalSetting: React.FC<ModalSettingProps> = ({ isOpen, toggle, methodsdata, currentId, setAction,getpaymentmethod }) => {
    const[data,setData]= useState<MethodData[]>([]);

    useEffect(() => {
      if (methodsdata && Array.isArray(methodsdata)) {
        const filteredMethodsData = methodsdata.filter(item => item.seq === currentId);
        setData(filteredMethodsData);
      }
    }, [methodsdata, currentId]);
    



const DeleteProfile = async (id: any) => {
    try {
      const response = await deletePromoterPaymentmethod(id);

      if (response.data && response.status === 200) {
   toggle()
        getpaymentmethod()
        // Update profileData using setProfileData
        toast.success("Account successfully deleted!", { autoClose: 2000 });

      }
    } catch (error) {
      console.error("Failed to fetch profile data", error);
    }
  };



const formatPhoneNumber = (phoneNumber) => {
    const phoneStr = phoneNumber.toString();
    const hiddenPart = phoneStr.slice(0, -4).replace(/\d/g, "*");
    const visiblePart = phoneStr.slice(-4);
    return hiddenPart + visiblePart;
  };
  const formatEmailUsername = (email) => {
    const [username, domain] = email.split("@");
    const hiddenPart = username.slice(0, -2).replace(/./g, "*");
    const visiblePart = username.slice(-2);
    return hiddenPart + visiblePart + "@" + domain;
  };


  const AddPrimary = async (id: any) => {
    try {
      const response = await setPrimaryAccount(id);
      if (response.data && response.status === 200) {
        // Update profileData using setProfileData
        setAction(prevAction => !prevAction);    
        toggle()

        toast.success("Account successfully set as Primary!", { autoClose: 2000 });

        // getpaymentmethod()
    }
    } catch (error) {
      console.error("Failed to fetch profile data", error);
    }
  };
  return (
    <>
      <ToastContainer />
      <Modal isOpen={isOpen} toggle={toggle} className={`mt-5 ${styles.modal}`}>
        <ModalBody>
          <div className="row">
            <div className="col-md-12">
              <div className="mb-3">
                <label className="form-label" style={{fontSize:"20px"}}>Payment Method </label>
                   
                {data &&
                    data.map((item, index) => {
                      return (
                        <>
                          <div
                          
                            key={index}
                          >
                            <div className="row">
                              {item?.method === "UPI" ? (
                                <>
                                  <div className="col-2">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 384 512"
                                      width={50}
                                      height={50}
                                    >
                                      <path d="M111.4 295.9c-3.5 19.2-17.4 108.7-21.5 134-.3 1.8-1 2.5-3 2.5H12.3c-7.6 0-13.1-6.6-12.1-13.9L58.8 46.6c1.5-9.6 10.1-16.9 20-16.9 152.3 0 165.1-3.7 204 11.4 60.1 23.3 65.6 79.5 44 140.3-21.5 62.6-72.5 89.5-140.1 90.3-43.4 .7-69.5-7-75.3 24.2zM357.1 152c-1.8-1.3-2.5-1.8-3 1.3-2 11.4-5.1 22.5-8.8 33.6-39.9 113.8-150.5 103.9-204.5 103.9-6.1 0-10.1 3.3-10.9 9.4-22.6 140.4-27.1 169.7-27.1 169.7-1 7.1 3.5 12.9 10.6 12.9h63.5c8.6 0 15.7-6.3 17.4-14.9 .7-5.4-1.1 6.1 14.4-91.3 4.6-22 14.3-19.7 29.3-19.7 71 0 126.4-28.8 142.9-112.3 6.5-34.8 4.6-71.4-23.8-92.6z" />
                                    </svg>
                                  </div>
                                  <div className="col-4">
                                    <p>Payment Method</p>
                                    <p>UPI ID</p>
                                  </div>
                                  <div className="col-4">
                                    <p>
                                      <b>{item?.methodName}</b>
                                    </p>
                                    <p>
                                      <b>{item?.RefId}</b>{" "}
                                    </p>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="col-2">
                                    <i
                                      className="mdi mdi-bank"
                                      style={{ fontSize: "50px" }}
                                    ></i>
                                  </div>

                                  <div className="col-4">
                                    <p>Payment Method</p>
                                    <p>Account Type</p>
                                    <p>Bank Account</p>
                                  </div>
                                  <div className="col-4">
                                    <p>
                                      <b>{item?.methodName}</b>
                                    </p>
                                    <p>
                                      <b>{item?.Type}</b>{" "}
                                    </p>
                                    <p>
                                      <b>
                                        {item?.RefNumber}
                                      </b>
                                    </p>
                                  </div>
                                </>
                              )}


      <div className="col-2">
                             
                              <div className="col-1">

                              {item?.IsPrimary === false ? (
                                <button
                                  type="button"
                                  style={{
                                    backgroundColor: "transparent",
                                    outline: "none",
                                    border: "none",
                                    color:'red'
                                  }}
                                  onClick={() => DeleteProfile(item?.seq)}
                                >
                                  <i
                                    className="mdi mdi-delete-outline d-block "
                                    style={{ fontSize: "25px" }}
                                  ></i>
                                </button>):''}
                              </div>
                            
                              </div>
                            </div>

                            <div className="row  mt-2 d-flex justify-content-center">
                                
                            <button
  onClick={() => AddPrimary(item?.seq)}
  className={item?.IsPrimary === true ? "disable me-3 mx-2 " : "enable me-3 mx-2 "}

  style={{ backgroundColor: "#642e5c" ,color:'white',width:"fit-content",padding:"10px",borderRadius:"5px"}}
  
>
{item?.IsPrimary === true ? "Primary Account":"Mark as Primary"}
                               </button>
                               <button
  onClick={() => toggle()}
  className={item?.IsPrimary === true ? "disable mx-2 " : "enable mx-2 "}

  style={{ backgroundColor: "#642e5c" ,color:'white',width:"fit-content",padding:"10px",borderRadius:"5px"}}
>
Close


                               </button>
                            </div>
                          </div>

                          
                        </>
                      );
                    })}
                   
              </div>
            </div>

            
          </div>

          <div>
         
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ModalSetting;
