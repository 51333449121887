
//production
export const promoter_baseUrl ="https://finsider.skartio.app/promoterinsider/api/110/";
export const identity_baseUrl ="https://identity.skartio.app/identityframe/api/110";

//staging
//  export const promoter_baseUrl = "http://192.168.1.210:26810/promoterinsider/api/110/" //"http://192.168.1.210:29710/promoterframe/api/110/";
//  export const identity_baseUrl ="http://192.168.1.210:20310/identityframe/api/110";


// SKARTIOELETRONICS : 39fb2455fcABQ
// SKARTIOFASHION  : b03c1e7a34ABV
// SKARTIOGROCERY  : 3ff37c4c32ABW
// MOBILE          : b1d790325eAKW
