import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input } from "reactstrap";
import styles from './login-otp-phone.module.scss';
import { setCookie, setToken } from "assistance/user_service";
import { loginUser } from "assistance/identity_service";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

interface OTPModalProps {
  isOpen: boolean;
  toggle: () => void;
  formData: any;
}

const LoginOTPModalPhone: React.FC<OTPModalProps> = ({ isOpen, toggle, formData }) => {
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [otpError, setOtpError] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const value = e.target.value;
    if (/^[0-9]?$/.test(value)) { // Allow only single digits
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move to the next input field or go back on delete
      if (value && index < otp.length - 1) {
        (document.getElementById(`otp-input-${index + 1}`) as HTMLInputElement)?.focus();
      } else if (!value && index > 0) {
        (document.getElementById(`otp-input-${index - 1}`) as HTMLInputElement)?.focus();
      }
    }
  };

  const submitForm = async (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
  
    if (otp.includes("")) {
      setOtpError(true);
      return;
    } else {
      setOtpError(false);
    }
  
    // Join the otp array into a single string
    const otpString = otp.join("");
  
    try {
      const response = await loginUser(
        "02",
       "",
       formData?.Phone,
        "PHONE",
        otpString,
        "U3"
      );

      if (response.status === 200) {
        setOtpError(false);
        setToken(response?.data?.data?.token);
        toast.success("Login Successfully");

        const callbackUrl = '/dashboard';
        
        setTimeout(() => {
          window.location.href = callbackUrl;    
        }, 1000);
      } else {
        setOtpError(true);
      }
    } catch (error) {
      console.error("Login error:", error);
      setOtpError(true);
    }
  };

  return (

    <>
                          <ToastContainer/>

  
    <Modal isOpen={isOpen} toggle={toggle} className={`mt-5 ${styles.modal}`}>
      <ModalHeader toggle={toggle}>OTP Verification</ModalHeader>
      <ModalBody>
        <div className={styles["otp-inputs"]}>
          {otp.map((digit, index) => (
            <div className="m-2" key={index}>
              <Input
                type="text"
                id={`otp-input-${index}`}
                 autoComplete="off"
                maxLength={1}
                value={digit}
                onChange={(e) => handleChange(e, index)}
                className={`${styles["otp-input"]} ${otpError ? styles.invalid : ''}`}
              />
            </div>
          ))}
        </div>
        {otpError && <p className={styles.errorText}>Please enter the full OTP.</p>}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>Close</Button>
        <Button color="primary" onClick={submitForm}>Verify</Button>
      </ModalFooter>
    </Modal>
    </>
  );
};

export default LoginOTPModalPhone;
