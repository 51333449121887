import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Input,
  Label,
  Form,
  FormFeedback,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import profileImg from "../../assets/images/profile-img.png";
// import logoImg from "../../assets/images/logo.svg";
import { createSelector } from "reselect";
import { apiError } from "../../slices/thunk";
import {
  fetchCountryList,
  verifyEmail,
} from "../Authentication/Registerfunctions"; // Import your JS functions
import OTPModal from "Components/partner-register-modal/partner-register-modal";
import { getStates } from "assistance/static_api";
import { toast } from "react-toastify";

interface Country {
  id: number;
  sortname: string;
  name: string;
  phoneCode: number;
}
interface State {
  id: number;
  name: string;
  country_id: number;
}



const Register = () => {
  const [selectedCode, setSelectedCode] = useState("91");
  const [selectCode, setSelectCode] = useState("101");
  const [phoneNum, setPhoneNum] = useState('');
  const [countryList, setCountryList] = useState<Country[]>([]);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [showOTP, setShowOTP] = useState(false);
  const [show, setShow] = useState(false);
  const [shows, setShows] = useState(false);

  const [data, setData] = useState(['']);
  const [stateList, setStateList] = useState<State[]>([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("IN");
  const [selectedRegion, setSelectedRegion] = useState('');

  const handleSelectChange = (e) => {
    setSelectedRegion(e.target.value);
  };
  const [formData, setFormData] = useState({
    email: "",
    username: "",
    password: "",
    state:"",
    type: "",
    phone: "",
    country: "India",
  });
  const [selectedCountryDetail, setSelectedCountryDetail] = useState({
    id: "101",
    code: "IN",
    name: "India",
  });

  useEffect(() => {

    // debugger
    // Get the 'fromData' query parameter from the URL
    const urlParams = new URLSearchParams(window.location.search);
    const fromDataParam = urlParams.get('fromData');

    if (fromDataParam) {
      // Decode and parse the data

      const decodedData = JSON.parse((fromDataParam));

      // Store the data in an array
      // console.log(decodedData)
      setData(decodedData)
      // debugger
      
      
      setFormData((prevFormData) => ({
        ...prevFormData,
        country: decodedData?.country,
        email: decodedData?.email,
        state:decodedData?.state,
        username: decodedData?.username,
        password: decodedData?.password,
        type: "",
        phone:"91" + "-" + decodedData?.phone,
      }));

      verifyEmail(decodedData, setShowOTP,setShows)
        .then((result) => {
          // console.log(result.message);
        })
        .catch((error) => {
          console.error("Verification failed", error);
        });

      // if(shows === true){
      //   toast.error('You have already Account Please login')
      // }

    } else {
      // console.log('No fromData found in the URL.');
    }
    const cachedCountries = localStorage.getItem('countryList');
    if (cachedCountries) {
      setCountryList(JSON.parse(cachedCountries));
      setLoading(false);
    } else {
      const getCountries = async () => {
        try {
          const countries = await fetchCountryList();
          setCountryList(countries);
          localStorage.setItem('countryList', JSON.stringify(countries));
        } catch (err) {
          console.error("Error fetching countries:", err);
        } finally {
          setLoading(false);
        }
      };
      getCountries();
    }

  }, []);

  useEffect(() => {
    if (selectCode) {
      // Check if states for the selected country are already cached
      const cachedStates = sessionStorage.getItem(`states_${selectCode}`);
      if (cachedStates) {
        setStateList(JSON.parse(cachedStates)); // Use cached states
      } else {
        const getState = async (selectCode :any) => {
          try {
            const data = await getStates(selectCode); // Call API for states based on country selectedCode
            setStateList(data?.data?.data);
            sessionStorage.setItem(`states_${selectCode}`, JSON.stringify(data?.data?.data)); // Cache states for the selected country
          } catch (err) {
            console.error("Error fetching states:", err);
          } finally {
            setLoading(false);
          }
        };
        getState(selectCode);
      }
    }
  }, [selectCode]);

  // console.log(stateList)




  const handleCodeChange = (e) => {
    setSelectedCode(e.target.value);
  };

  // Handle country selection and update phone code
  const handleCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCountry = e.target.value;
    handleInputChange(e); // Keep this to update form data
  
    // Ensure phoneCode is converted to a string
    const country = countryList.find((c) => c.name === selectedCountry);
    const phoneCodeString = country?.phoneCode.toString() || "";
    const codeString = country?.id.toString() || "";

    setSelectedCode(phoneCodeString);
    setSelectCode(codeString)
    // Dynamically set country details
// Dynamically set country details
setSelectedCountryDetail({
  id: country?.id?.toString() || "", // Convert id to string if it exists, otherwise use an empty string
  code: country?.phoneCode?.toString() || "", // Convert phoneCode to string if it exists, otherwise use an empty string
  name: country?.name || "",
});

  
    setFormData((prevFormData) => ({
      ...prevFormData,
      country: selectedCountry,
    }));
  
    // console.log(phoneCodeString); // Updated to log the local variable directly
  };
  

  const dispatch = useDispatch<any>();
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email:  "",
      username: "",
      password: "",
      type: "",
      phone: "",
      country: formData.country || "", // Bind initialValues to formData
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email format")
        .required("Please Enter Your Email"),
      username: Yup.string()
        .matches(/^[A-Za-z\s]/, "Username must contain only letters")
        .min(3, "Username must be at least 3 characters long")
        .required("Please Enter Your Username"),
      password: Yup.string()
        .min(8, "Password must be at least 8 characters long")
        .required("Please Enter Your Password"),

      type: Yup.string().required("Please Choose a type"),
      phone: Yup.string()
        .matches(/^[0-9]+$/, "Phone number must contain only digits")
        .min(10, "Phone number must be at least 10 digits")
        .max(10, "Phone number max 10 digits")
        .required("Please Enter Your Phone number"),
      country: Yup.string().required("Please Select a Country"),
    }),
    onSubmit: (values) => {

      const combinedPhone = `${selectedCode}-${values.phone}`;
      const finalData = {
        ...values,
        phone: combinedPhone,
      };
      // console.log(finalData);
      const value =sessionStorage.getItem('emailID')
      setFormData({
        username: finalData?.username,
        email:value? value : finalData?.email ,
        phone:finalData?.phone,
        state:selectedState,
        country: finalData?.country,
        password: finalData.password, // Corrected this
        type: finalData?.type,
      });
      
      sessionStorage.setItem('datas',JSON.stringify(formData))

      verifyEmail(finalData, setShowOTP,)
        .then((result) => {
          // console.log(result.message);
        })
        .catch((error) => {
          console.error("Verification failed", error);
        });
    },
  });

  const selectProperties = createSelector(
    (state: any) => state.Account,
    (account) => ({
      user: account.user,
      registrationError: account.registrationError,
      loading: account.loading,
    })
  );

  const { user, registrationError } = useSelector(selectProperties);

  useEffect(() => {
    dispatch(apiError());
  }, [dispatch]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'phone') {
      setPhoneNum(value)
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    validation.setFieldValue(name, value); // Update Formik field value
  };

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block ">
        <Link to="https://insider.skartio.com/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-2 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div style={{ backgroundColor: "#642e5c" }}>
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h3 className="text-white">Register</h3>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="https://insider.skartio.com/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-white">
                          <img
                            src={
                              "https://skartiocloud.sgp1.cdn.digitaloceanspaces.com/SKARTIO1/THEME/1728039227079-download.png"
                            }
                            alt="Insider"
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={validation.handleSubmit}
                    >
                      {user && (
                        <Alert color="success">
                          Register User Successfully
                        </Alert>
                      )}
                      {registrationError && (
                        <Alert color="danger">{registrationError}</Alert>
                      )}
                      <div className="mb-3">
                        <Label className="form-label">Name</Label>
                        <Input
                          name="username"
                          type="text"
                          autoComplete="off"
                          placeholder="Enter username"
                          onChange={handleInputChange}
                          onBlur={validation.handleBlur}
                          value={formData.username || ""}
                          invalid={
                            validation.touched.username &&
                              validation.errors.username
                              ? true
                              : false
                          }
                        />
                        {validation.touched.username &&
                          validation.errors.username && (
                            <FormFeedback type="invalid">
                              {validation.errors.username}
                            </FormFeedback>
                          )}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          id="email"
                          name="email"
                          autoComplete="off"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={handleInputChange}
                          onBlur={validation.handleBlur}
                          value={formData.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email &&
                          validation.errors.email && (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          )}
                      </div>
                      <div className="mb-3">
                        <label className="d-block mb-3 form-label">
                          Register Type:
                        </label>
                        <div className="form-check form-check-inline">
                          <Input
                            id="customRadioInline1"
                            name="type"
                            type="radio"
                            className="form-check-input"
                            value="Affiliate"
                            onChange={handleInputChange}
                          />
                          <Label className="form-check-label">Affiliate</Label>
                        </div>
                        <div className="form-check form-check-inline">
                          <Input
                            id="customRadioInline2"
                            name="type"
                            type="radio"
                            className="form-check-input"
                            value="Agency"
                            onChange={handleInputChange}
                          />
                          <Label className="form-check-label">Agency</Label>
                        </div>
                        <div className="form-check form-check-inline">
                          <Input
                            id="customRadioInline3"
                            name="type"
                            type="radio"
                            className="form-check-input"
                            value="Developer"
                            onChange={handleInputChange}
                            disabled={true} // This will disable the radio button
                          />
                          <Label className="form-check-label">Developer</Label>
                        </div>
                        {validation.touched.type && validation.errors.type && (
                          <div className="text-danger">
                            {validation.errors.type}
                          </div>
                        )}
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Current Country</label>
                        <select
                          className={`form-select ${validation.touched.country &&
                              validation.errors.country
                              ? "is-invalid"
                              : ""
                            }`}
                          name="country"
                          onChange={handleCountryChange} // Make sure this function is used here
                          value={formData.country || ""}
                        >
                          <option value="">Select Country</option>
                          {countryList &&
                            countryList?.map((country, index) => (
                              <option key={index} value={country.name}>
                                {country.name}
                              </option>
                            ))}
                        </select>

                        {validation.touched.country &&
                          validation.errors.country && (
                            <div className="invalid-feedback">
                              {validation.errors.country}
                            </div>
                          )}
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Current State</label>

                        <select
                          id="region"
                          className={`form-select ${validation.touched.country &&
                              validation.errors.country
                              ? "is-invalid"
                              : ""
                            }`}
                          value={selectedRegion}
                          onChange={handleSelectChange}
                        >
                          <option value="">Select a State</option>
                          {stateList?.map((region) => (
                            <option key={region.id} value={region.name}>
                              {region.name}
                            </option>
                          ))}
                        </select>

                        {/* {validation.touched.country &&
                          validation.errors.country && (
                            <div className="invalid-feedback">
                              {validation.errors.country}
                            </div>
                          )} */}
                      </div>

                      <div className="mb-3">
                        <label className="form-label">Phone</label>
                        <div className="input-group">
                          <select
                            className="form-select"
                            value={selectedCode}
                            onChange={handleCodeChange} // Ensure this is being handled properly
                            style={{ maxWidth: "70px" }}
                          >
                            {countryList &&
                              countryList?.map((country, index) => (
                                <option
                                  key={index}
                                  value={country.phoneCode.toString()}
                                >
                                  {country.phoneCode}
                                </option>
                              ))}
                          </select>
                          <Input
                            className="form-control"
                            type="tel"
                            name="phone"
                            autoComplete="off"
                            value={phoneNum || ""}
                            onChange={handleInputChange}
                            onBlur={validation.handleBlur}
                            placeholder="Enter phone number"
                            invalid={
                              validation.touched.phone &&
                                validation.errors.phone
                                ? true
                                : false
                            }
                          />
                        </div>
                        {validation.touched.phone &&
                          validation.errors.phone && (
                            <FormFeedback type="invalid">
                              {validation.errors.phone}
                            </FormFeedback>
                          )}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <div className="input-group auth-pass-inputgroup">

                          <Input
                            name="password"
                             autoComplete="off"
                            type={show ? "text" : "password"}
                            placeholder="Enter Password"
                            onChange={handleInputChange}
                            onBlur={validation.handleBlur}
                            value={formData.password || ""}
                            invalid={
                              validation.touched.password &&
                                validation.errors.password
                                ? true
                                : false
                            }
                          />
                          <button
                            onClick={() => setShow(!show)}
                            className="btn btn-light"
                            type="button"
                            id="password-addon"
                          >
                            <i className="mdi mdi-eye-outline"></i>
                          </button>

                        </div>
                        {validation.touched.password &&
                          validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mt-4">
                        <button
                          className="btn text-white w-100"
                          style={{ backgroundColor: "#642e5c" }}
                          type="submit"
                        >
                          Register
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Already have an account?{" "}
                  <Link to="/login" className="fw-medium text-primary">
                    Log in to continue
                  </Link>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        {showOTP && (
          <OTPModal
            isOpen={showOTP}
            toggle={() => setShowOTP(false)} // Update toggle function
            formData={formData}
            selectedRegion={selectedRegion}
          // setShowOTP={setShowOTP}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default Register;
