import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Button,
  Input,
  Form,
  FormGroup,
} from "reactstrap";
import classnames from "classnames";
import { Link } from "react-router-dom";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
//Dropzone
import Dropzone from "react-dropzone";
//Import Breadcrumb

//Import images
import verificationImg from "../../assets/images/verification-img.png";
import { addKyc, getDocType, kycDoc } from "assistance/promoterClient_service";
import { fetchCountryList } from "pages/Authentication/Registerfunctions";
import { data } from "jquery";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface DocumentType {
  id: string;
  value: string;
  desc: string;
  country: string;
}

interface Country {
  name: string;
  phoneCode: string;
  sortname: string;
}

type KYCProps = {
  profileData: any; // Change this to accept MethodData[]
  
  setdata:React.Dispatch<React.SetStateAction<boolean>>;
};

const KYCApplication: React.FC<KYCProps> = ({ profileData ,setdata}) => {
  //meta title

  const [modal, setModal] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<number>(1);
  const [selectedFiles, setSelectedFiles] = useState<any>([]);

  const [countryList, setCountryList] = useState<Country[]>([]);

  const [documentOptions, setDocumentOptions] = useState<DocumentType[]>([]);

  const [selectedDocument, setSelectedDocument] = useState<string>("");
  const [documentNumber, setDocumentNumber] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [formData, setFormData] = useState({
    kycfirstName: "",
    gender: "",
    kycbirthDate: "",
    country: "",
    selectedDocument: "",
    documentNumber: "",
  });

  const [attachment, setAttachment] = useState([]);
  const [fileError, setFileError] = useState("");
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [uploadedFile, setUploadedFile] = useState(null);

  const handleAcceptedFiles = async (files) => {
    const acceptedFiles = files.filter((file) => {
      return file.type.startsWith("image/");
    });

    if (acceptedFiles.length > 0) {
      setAttachment(acceptedFiles[0]);
      setSelectedFiles(acceptedFiles);

      try {
        const response = await kycDoc(acceptedFiles[0]);
        // console.log(response);
      } catch (error) {
        console.error("Error uploading file:", error);
        setFileError("There was an error uploading the file.");
      }
    } else {
      setFileError("Please upload a valid file.");
    }
    // console.log(attachment);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  const handleDateChange = (date) => {


        setFormData((prevData) => ({
          ...prevData,
          kycbirthDate: date[0], // Use date[0] assuming it's a single date
        }));

  
  };

  const today = new Date();
  const maxDate = new Date(today.setFullYear(today.getFullYear() - 18));

  const handleSubmit = async (data) => {
    // console.log("Form Data:", data); // Log the form data

    // console.log("Form Data:", data?.country); // Log the form data
    // console.log("Form Data:", data?.selectedDocument); // Log the form data
    // console.log("Form Data:", data?.kycfirstName); // Log the form data
    // console.log("Form Data:", data?.gender); // Log the form data
    // console.log("Form Data:", data?.documentNumber); // Log the form data

    const formattedDate = data?.kycbirthDate.toISOString().split("T")[0];
    // console.log(formattedDate); // Output: "1999-11-10" (in YYYY-MM-DD format)

    // Convert to DD-MM-YYYY format
    const day = data?.kycbirthDate.getDate().toString().padStart(2, "0");
    const month = (data?.kycbirthDate.getMonth() + 1)
      .toString()
      .padStart(2, "0"); // Months are zero-indexed
    const year = data?.kycbirthDate.getFullYear();
    const formattedDateDDMMYYYY = `${day}-${month}-${year}`;
    // console.log(formattedDateDDMMYYYY);

    try {
      const response = await addKyc(
        data?.kycfirstName,
        data?.gender,
        data?.kycbirthDate,
        "",
        data?.selectedDocument,
        data?.documentNumber
      );

      if (response.data && response.status === 200) {
        toast.success("KYC submission successful!");
        togglemodal();
        setdata(true)

      } else if (response.status === 203) {
        toast.warn("Data Is already exists");
      }
    } catch (err) {
      console.error("Error submitting KYC:", err);
      toast.error("An error occurred while submitting KYC. Please try again.");
    }
  };

  const handleDocumentChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedDocument(e.target.value);
    setError("");
    setDocumentNumber("");
    setFormData({ ...formData, selectedDocument: e.target.value });
  };

  const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const number = e.target.value;
    setDocumentNumber(number);
    setFormData({ ...formData, documentNumber: e.target.value });
    // Basic validation based on selected document type
    if (selectedDocument === "Aadhaar Card" && !/^\d{12}$/.test(number)) {
      setError("Aadhaar Card must be 12 digits.");
    } else if (
      selectedDocument === "PAN Card" &&
      !/^[A-Z]{5}\d{4}[A-Z]$/.test(number)
    ) {
      setError("PAN Card must be in format: ABCDE1234F.");
    } else if (
      selectedDocument === "Indian Passport" &&
      !/^[A-Z]\d{7}$/.test(number)
    ) {
      setError("Passport must be in format: A1234567.");
    } else {
      setError("");
    }
  };

  useEffect(() => {
    const fetchDocumentTypes = async () => {
      try {
        const response = await getDocType();
        if (response.status === 200) {
          // console.log(response?.data?.data); // Log the response data
          setDocumentOptions(response?.data?.data); // Set the fetched data to state
        }
      } catch (error) {
        console.error("Error fetching document types:", error);
      }
    };

    fetchDocumentTypes();
  }, []);

  useEffect(() => {
    const getCountries = async () => {
      try {
        const countries = await fetchCountryList(); // Call the function from JS file
        setCountryList(countries);
        // console.log(countries);
      } catch (err) {
        console.error("Error fetching countries:", err);
      }
    };
    getCountries();
  }, []);

  const togglemodal = () => {
    setModal(!modal);
  };

  // console.log(documentOptions);
  const [passedSteps, setPassedSteps] = useState([1]);

  const toggleTab = (tab: any) => {
    if (activeTab !== tab) {
      const modifiedSteps = [...passedSteps];
      modifiedSteps.push(tab);
      setActiveTab(tab);
      setPassedSteps(modifiedSteps);
    }
  };

  // console.log("profileData",profileData?.onboardingSteps?.kycFlag)

  return (
    <React.Fragment>
      <div className="">
        <ToastContainer />

        <Container fluid>
          <Row className="justify-content-center ">
            <Col xl={12} sm={8}>
              <Card>
                <CardBody>
                  <div className="text-center">
                    <Row className="justify-content-center">
                      <Col lg={12}>
                        <h4 className=" fw-semibold">KYC Verification </h4>
                        <p className="text-muted mt-3">
                          Itaque earum rerum hic tenetur a sapiente delectus ut
                          aut reiciendis perferendis asperiores repellat.{" "}
                        </p>
                        <div className="mt-4">
                          {profileData?.KYC?.KYCStatus === "R" ? (
                            <>
                              <Button
                                type="button"
                                style={{ backgroundColor: "red" }}
                                // onClick={togglemodal}
                              >
                                 KYC Verification Rejected{" "}
                              </Button>
                              {/* <p>Contact the Admin</p> */}
                            </>
                          ) : profileData?.KYC?.KYCStatus === "V" ? (
                            <>
                              <Button
                                type="button"
                                style={{ backgroundColor: "green" }}
                                // onClick={togglemodal}
                              >
                                Verified{" "}
                              </Button>
                              {/* <p>Contact the Admin</p> */}
                            </>
                          ) :profileData?.onboardingSteps?.kycFlag === true ? (
                            <Button
                              type="button"
                              style={{ backgroundColor: "#642e5c" }}
                              onClick={togglemodal}
                            >
                              Awaiting approval{" "}
                            </Button>
                          ) : profileData?.onboardingSteps?.kycFlag ===
                            false ? (
                            <Button
                              type="button"
                              style={{ backgroundColor: "#642e5c" }}
                              onClick={togglemodal}
                            >
                              Click here for Verification{" "}
                            </Button>
                          ) : (
                            ""
                          )}
                        </div>
                      </Col>
                    </Row>
                    <Row className="justify-content-center mt-5 mb-2">
                      <Col sm={6} xs={8}>
                        <div>
                          <img
                            src={verificationImg}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>

                  {/* modal */}
                  <Modal
                    isOpen={modal}
                    role="dialog"
                    size="lg"
                    autoFocus={true}
                    centered
                    id="verificationModal"
                    toggle={togglemodal}
                  >
                    <ModalHeader toggle={togglemodal}>
                      Verify KYC Details{" "}
                    </ModalHeader>
                    <ModalBody>
                      <div id="kyc-verify-wizard" className="wizard clearfix">
                        <div className="steps clearfix">
                          <ul>
                            <NavItem
                              className={classnames({
                                current: activeTab === 1,
                              })}
                            >
                              <NavLink
                                className={classnames({
                                  active: activeTab === 1,
                                })}
                                onClick={() => toggleTab(1)}
                                disabled={!passedSteps.includes(1)}
                              >
                                <span
                                  className="number"
                                  style={{
                                    backgroundColor:
                                      activeTab === 1 ? "#642e5c" : "white",
                                    color:
                                      activeTab === 1 ? "white" : "#642e5c",
                                    border:
                                      activeTab === 1
                                        ? "2px solid #642e5c"
                                        : "2px solid #642e5c",
                                  }}
                                >
                                  1.
                                </span>
                                Personal Info
                              </NavLink>
                            </NavItem>
                            <NavItem
                              className={classnames({
                                current: activeTab === 2,
                              })}
                            >
                              <NavLink
                                className={classnames({
                                  active: activeTab === 2,
                                })}
                                onClick={() => toggleTab(2)}
                                disabled={!passedSteps.includes(2)}
                              >
                                <span
                                  className="number"
                                  style={{
                                    backgroundColor:
                                      activeTab === 2 ? "#642e5c" : "white",
                                    color:
                                      activeTab === 2 ? "white" : "#642e5c",
                                    border:
                                      activeTab === 2
                                        ? "2px solid #642e5c"
                                        : "2px solid #642e5c",
                                  }}
                                >
                                  2.
                                </span>
                                Document Verification
                              </NavLink>
                            </NavItem>
                          </ul>
                        </div>
                        <div className="content clearfix">
                          {profileData?.onboardingSteps?.kycFlag === false ? (
                            <TabContent
                              activeTab={activeTab}
                              className="twitter-bs-wizard-tab-content"
                            >
                              <TabPane
                                tabId={1}
                                id="personal-info"
                                className="body current"
                              >
                                <Form autoComplete="off">
                                  <Row>
                                    <Col lg={6}>
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="kycfirstname-input">
                                          First name
                                        </Label>
                                        <Input
                                          type="text"
                                          autoComplete="off"
                                          id="kycfirstname-input"
                                          name="kycfirstName"
                                          placeholder="Enter First name"
                                          value={formData.kycfirstName}
                                          onChange={handleInputChange}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col lg={6}>
                                      <Label className="d-block mb-3">
                                        Gender:
                                      </Label>
                                      <div className="form-check form-check-inline">
                                        <Input
                                          type="radio"
                                          id="customRadioInline1"
                                          autoComplete="off"
                                          className="form-check-input"
                                          name="gender"
                                          value="Male"
                                          checked={formData.gender === "Male"}
                                          onChange={handleInputChange}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="customRadioInline1"
                                        >
                                          Male
                                        </label>
                                      </div>
                                      <div className="form-check form-check-inline">
                                        <Input
                                          type="radio"
                                          autoComplete="off"
                                          id="customRadioInline2"
                                          className="form-check-input"
                                          name="gender"
                                          value="Female"
                                          checked={formData.gender === "Female"}
                                          onChange={handleInputChange}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="customRadioInline2"
                                        >
                                          Female
                                        </label>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg={6}>
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="kycbirthdate-input">
                                          Date of birth
                                        </Label>
                                        <Flatpickr
      className="form-control"
      name="kycbirthDate"
      placeholder="Select date"
      options={{
        dateFormat: "d M, Y",
        maxDate, // Restrict selection to dates before this date
      }}
      onChange={(date) => handleDateChange(date)}
    />
                                      </FormGroup>
                                    </Col>
                                    <Col lg={6}>
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom04">
                                          Country
                                        </Label>
                                        <select
                                          name="country"
                                          className="form-select"
                                          value={formData.country}
                                          onChange={handleInputChange}
                                        >
                                          <option value="" disabled>
                                            Select Country
                                          </option>
                                          {countryList.map((country, index) => (
                                            <option
                                              key={index}
                                              value={`${country.name},${country.sortname}`}
                                            >
                                              {country.name}
                                            </option>
                                          ))}
                                        </select>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </Form>
                              </TabPane>
                              <TabPane tabId={2} id="Document Verification">
                                <Form autoComplete="off">
                                  <Row>
                                    <Col lg={6}>
                                      <select
                                        className="form-select"
                                        value={formData.selectedDocument}
                                        onChange={handleDocumentChange}
                                      >
                                        <option value="">
                                          Select Document
                                        </option>
                                        {documentOptions.map((doc) => (
                                          <option
                                            key={doc.id}
                                            value={doc.value}
                                          >
                                            {doc.value}
                                          </option>
                                        ))}
                                      </select>
                                    </Col>
                                    <Col lg={6}>
                                      <Input
                                        type="text"
                                        placeholder="Enter Document Number"
                                        value={formData.documentNumber}
                                        onChange={handleNumberChange}
                                      />
                                      {error && (
                                        <p style={{ color: "red" }}>{error}</p>
                                      )}
                                    </Col>
                                  </Row>
                                  <Row>
                                    {/* <h5 className="font-size-14 mb-3">Upload document file for verification</h5> */}
                                    <div className="kyc-doc-verification mb-3 mt-3">
                                      {/* <Dropzone> */}
                                      <Dropzone
                                        onDrop={(acceptedFiles) =>
                                          handleAcceptedFiles(acceptedFiles)
                                        }
                                      >
                                        {({ getRootProps, getInputProps }) => (
                                          <div
                                            {...getRootProps({
                                              className: "dropzone",
                                            })}
                                          >
                                            <input {...getInputProps()} />

                                            <div className="mb-3 mt-3  d-flex justify-content-center">
                                              <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                                            </div>
                                            <h5 className="font-size-14 d-flex justify-content-center">
                                              Upload document file for
                                              verification
                                            </h5>

                                            {fileError && (
                                              <div className="text-danger">
                                                {fileError}
                                              </div>
                                            )}
                                          </div>
                                        )}
                                      </Dropzone>
                                      <ul
                                        className="list-unstyled mb-0"
                                        id="file-previews"
                                      >
                                        {selectedFiles.map((file, index) => (
                                          <li
                                            className="mt-2 dz-image-preview"
                                            key={index}
                                          >
                                            <div className="border rounded">
                                              <div className="d-flex flex-wrap gap-2 p-2">
                                                <div className="flex-shrink-0 me-3">
                                                  <div className="avatar-sm bg-light rounded p-2">

                                                  {file.preview ? (
                                                    <img
                                                      data-dz-thumbnail=""
                                                      className="img-fluid  rounded d-block"
                                                      style={{ height: "20px" }}
                                                      src={file.preview }
                                                      alt={file.name}
                                                    />
                                                  ):(
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#642e5c"><path d="M0 96C0 60.7 28.7 32 64 32l384 0c35.3 0 64 28.7 64 64l0 320c0 35.3-28.7 64-64 64L64 480c-35.3 0-64-28.7-64-64L0 96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6l96 0 32 0 208 0c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"/></svg>
                                                  )}
                                                  </div>
                                                </div>
                                                <div className="flex-grow-1">
                                                  <div className="pt-1">
                                                    <h5 className="fs-14">
                                                      {file.name}
                                                      <span className="text-muted font-size-12 ms-2">
                                                        ({file.size} Bytes)
                                                      </span>
                                                    </h5>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  </Row>
                                  {/* <Button type="button" onClick={() => handleSubmit(formData)}>Submit</Button> */}
                                </Form>
                              </TabPane>
                            </TabContent>
                          ) : (
                            <TabContent
                              activeTab={activeTab}
                              className="twitter-bs-wizard-tab-content"
                            >
                              <TabPane
                                tabId={1}
                                id="personal-info"
                                className="body current"
                              >
                                <Form autoComplete="off">
                                  <Row>
                                    <Col lg={6}>
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="kycfirstname-input">
                                          First name
                                        </Label>
                                        <Input
                                          type="text"
                                          autoComplete="off"
                                          id="kycfirstname-input"
                                          name="kycfirstName"
                                          placeholder="Enter First name"
                                          value={profileData?.KYC?.legalName}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col lg={6}>
                                      <Label className="d-block mb-3">
                                        Gender:
                                      </Label>
                                      <div className="form-check form-check-inline">
                                        <Input
                                          type="radio"
                                          id="customRadioInline1"
                                          autoComplete="off"
                                          className="form-check-input"
                                          name="gender"
                                          value="Male"
                                          checked={
                                            profileData?.KYC?.gender === "Male"
                                          }
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="customRadioInline1"
                                        >
                                          Male
                                        </label>
                                      </div>
                                      <div className="form-check form-check-inline">
                                        <Input
                                          type="radio"
                                          autoComplete="off"
                                          id="customRadioInline2"
                                          className="form-check-input"
                                          name="gender"
                                          value="Female"
                                          checked={
                                            profileData?.KYC?.gender ===
                                            "Female"
                                          }
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="customRadioInline2"
                                        >
                                          Female
                                        </label>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg={6}>
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="kycbirthdate-input">
                                          Date of birth
                                        </Label>
                                        <Flatpickr
                                          className="form-control"
                                          name="kycbirthDate"
                                          placeholder="Select date"
                                          value={profileData?.KYC?.DOB}
                                          // options={{ dateFormat: "d M, Y" }}
                                          // onChange={(date) => handleDateChange(date)}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col lg={6}>
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom04">
                                          Country
                                        </Label>
                                        <select
                                          name="country"
                                          className="form-select"
                                          value={"India"}
                                          // onChange={handleInputChange}
                                        >
                                          {/* <option value="" disabled>Select Country</option> */}
                                          {/* {countryList.map((country, index) => ( */}
                                          <option value={`India`}>India</option>
                                          {/* ))} */}
                                        </select>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </Form>
                              </TabPane>
                              <TabPane tabId={2} id="Document Verification">
                                <Form autoComplete="off">
                                  <Row>
                                    <Col lg={6}>
                                      <select
                                        className="form-select"
                                        value={formData.selectedDocument}
                                      >
                                        <option value="">
                                          {profileData?.KYC?.IdType}
                                        </option>
                                      </select>
                                    </Col>
                                    <Col lg={6}>
                                      <Input
                                        type="text"
                                        placeholder="Enter Document Number"
                                        value={profileData?.KYC?.IdNumber}
                                      />
                                      {/* {error && <p style={{ color: "red" }}>{error}</p>} */}
                                    </Col>
                                  </Row>
                                  <Row>
                                    {/* <h5 className="font-size-14 mb-3">Upload document file for verification</h5> */}
                                    <div className="kyc-doc-verification mb-3 mt-3">
                                      {/* <Dropzone> */}

                                      <div
                                        style={{
                                          textAlign: "center",
                                          padding: "20px",
                                        }}
                                      >
                                        <a
                                          href={profileData?.KYC?.IdDocument}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          style={{
                                            display: "inline-block",
                                            padding: "10px 20px",
                                            // backgroundColor: '#0070f3',
                                            color: "#0070f3",
                                            fontSize: "15px",
                                            textDecoration: "none",
                                            borderRadius: "5px",
                                          }}
                                        >
                                          Click here to view the document
                                        </a>
                                      </div>
                                    </div>
                                  </Row>
                                  {/* <Button type="button" onClick={() => handleSubmit(formData)}>Submit</Button> */}
                                </Form>
                              </TabPane>
                            </TabContent>
                          )}
                        </div>

                        {profileData?.onboardingSteps?.kycFlag === false ? (
                          <div className="actions clearfix">
                            <ul role="menu" aria-label="Pagination">
                              <li
                                className={
                                  activeTab === 1
                                    ? "previous disabled"
                                    : "previous"
                                }
                              >
                                <Link
                                  to="#"
                                  onClick={(e) => {
                                    if (activeTab > 1) {
                                      toggleTab(activeTab - 1);
                                    }
                                  }}
                                  style={{
                                    backgroundColor:
                                      activeTab === 1 ? "#642e5c" : "white",
                                    color:
                                      activeTab === 1 ? "white" : "#642e5c",
                                    border: `2px solid ${
                                      activeTab === 1 ? "#642e5c" : "#642e5c"
                                    }`,
                                  }}
                                  aria-disabled={activeTab === 1}
                                >
                                  Previous
                                </Link>
                              </li>
                              <li
                                className={
                                  activeTab === 2 ? "#642e5c" : "white"
                                }
                              >
                                <Link
                                  to="#"
                                  onClick={(e) => {
                                    if (activeTab === 2) {
                                      handleSubmit(formData); // Pass formData or any required argument
                                    } else {
                                      toggleTab(activeTab + 1);
                                    }
                                  }}
                                  style={{
                                    backgroundColor:
                                      activeTab === 2 ? "#642e5c" : "white",
                                    color:
                                      activeTab === 2 ? "white" : "#642e5c",
                                    border: `2px solid ${
                                      activeTab === 2 ? "#642e5c" : "#642e5c"
                                    }`,
                                  }}
                                  aria-disabled={activeTab === 2}
                                >
                                  {activeTab === 2 ? "Submit" : "Next"}
                                </Link>
                              </li>
                            </ul>
                          </div>
                        ) : (
                          <div className="actions clearfix">
                            <ul role="menu" aria-label="Pagination">
                              <li
                                className={
                                  activeTab === 1
                                    ? "previous disabled"
                                    : "previous"
                                }
                              >
                                <Link
                                  to="#"
                                  onClick={(e) => {
                                    if (activeTab > 1) {
                                      toggleTab(activeTab - 1);
                                    }
                                  }}
                                  style={{
                                    backgroundColor:
                                      activeTab === 1 ? "#642e5c" : "white",
                                    color:
                                      activeTab === 1 ? "white" : "#642e5c",
                                    border: `2px solid ${
                                      activeTab === 1 ? "#642e5c" : "#642e5c"
                                    }`,
                                  }}
                                  aria-disabled={activeTab === 1}
                                >
                                  Previous
                                </Link>
                              </li>
                              {activeTab === 1 ? (
                                <li className={"white"}>
                                  <Link
                                    to="#"
                                    onClick={(e) => {
                                      toggleTab(activeTab + 1);
                                    }}
                                    style={{
                                      backgroundColor: "#642e5c",
                                      color: "white",
                                      border: `2px solid #642e5c`,
                                    }}
                                  >
                                    Next
                                  </Link>
                                </li>
                              ) : (
                                ""
                              )}
                            </ul>
                          </div>
                        )}
                      </div>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default KYCApplication;
