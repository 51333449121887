import { loginUser } from "assistance/identity_service";
import { setCookie, setToken } from "assistance/user_service";

export default async function passwordLogin(emailValue, passwordEncoded,setShows) {
  // setIsSubmitting(true);
  // setLoading(true);
  

  try {
    const response = await loginUser(
      "02",
      emailValue,
      passwordEncoded,
      "EMAIL",
      "",
      "U3"
    );

    if (response.status === 200) {
      const companyToken = response?.data?.data?.token;

      setToken(companyToken);
          
      if (companyToken) {
        const [header, payload, signature] = companyToken.split(".");
        try {
          const decodedPayload = JSON.parse(atob(payload));
          const expireDate = decodedPayload?.exp;

          if (decodedPayload && expireDate) {
            setCookie("token", companyToken, expireDate);
          }
        } catch (decodeError) {
          console.error("Error decoding token:", decodeError);
          // setLoading(false);
          // setIsSubmitting(false);
          return;
        }
      }

      setTimeout(() => {
        window.location.href = "/dashboard";
        // setLoading(false);
      }, 1000);
    } else  if (response.status === 200) {

      setTimeout(() => {
        window.location.href = "/register";
      }, 2000);

      
    }else  if (response.status === 203) {
      setShows(true)
    }
    else {
      handleResponseStatus(response.status);
    }
  } catch (error) {
    // setLoading(false);
    console.error("Login error:", error);
  }
}


// ********************************Handle non-200 status responses*********************************//


const handleResponseStatus = (status) => {
  // setIsSubmitting(false);

  if (status === 202) {
    setTimeout(() => {
      window.location.href = "/register";
    }, 2000);
  } else if (status === 203) {
    // setLoading(false);
  }
};



//************************ */  Password Login scenario ******************************//

export  async function handleLogin(selectedCode,phoneValue,setShowOTPs) {
  try {
    // Replace with your actual login function
    const response = await loginUser(
      "01",
      selectedCode+"-"+phoneValue,
      "",
      "PHONE",
      "", // Pass the joined OTP string here
      "U3"
    );

    if (response.status === 200) {
      setShowOTPs(true);

    } else if (response.status === 202) {
      // toast("You don't have an account, please register");
      setTimeout(() => {
       window.location.href = "/register"
      }, 2000);
    }
  } catch (error) {
    console.error("Login failed:", error); // Log any error that occurs
    // toast.error("An error occurred during login. Please try again.");
  }
};



