import React from "react";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../common/ChartDynamicColor";
import { ApexOptions } from "apexcharts";

interface StackedColumnChartProps {
  periodType: string; // 'year', 'month', or 'week'
}

const StackedColumnChart: React.FC<StackedColumnChartProps> = ({ periodType }) => {
  // Ensure colors are passed as an array
  const dataColors = '["#642e5c", "--bs-warning", "--bs-success"]';
  const stackedColumnChartColors = getChartColorsArray(dataColors);

  // Function to get categories based on periodType
  const getCategories = (type: string) => {
    switch (type) {
      case 'year':
        return ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      case 'month':
        return ["Week 1", "Week 2", "Week 3", "Week 4"];
      case 'week':
        return ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
      default:
        return ["Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct"]; // default categories
    }
  };

  const series = [
    {
      name: "MQL",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
    {
      name: "SQL",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
    {
      name: "PQL",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
    {
      name: "Sales",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
    }
  ];

  const options: ApexOptions = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "45%",
        // endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    colors: stackedColumnChartColors,
    xaxis: {
      categories: getCategories(periodType), // dynamically get categories
    },
    yaxis: {
      title: {
        // text: "$ (thousands)",
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val: any) {
          return "$ " + val + " thousands";
        },
      },
    },
  };

  return (
    <React.Fragment>
      <ReactApexChart options={options} series={series} type="bar" height={290} />
    </React.Fragment>
  );
};

export default StackedColumnChart;
