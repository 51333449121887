import React from "react"
import { Link } from "react-router-dom"
import { Container, Row, Col } from "reactstrap"

//Import Images
import error from "../../assets/images/coming-soon.svg"

const ComingSoon = () => {
    //meta title
    document.title = " Skartio - Insider";

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-5">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mt-2">
                {/* <h6 className="display-4 fw-medium">
               
                  Coming Soon
                </h6> */}
                <h4 className="text-uppercase text-center display-6">                  Coming Soon
                </h4>
              
              </div>
            </Col>
          </Row>

          <div className="justify-content-center mt-1 row"><div className="col-sm-4"><div className="maintenance-img"><img  src={error} alt="coming" className="img-fluid mx-auto d-block"/></div></div></div>
          {/* <Row className="justify-content-center">
            <Col md="8" xl="6">
              <div>
                <img src={error} alt="" className="img-fluid"   />
              </div>
            </Col>
          </Row> */}
          <h4 className="mt-5 d-flex justify-content-center ">Let's get started with Skartio</h4>
          {/* <p className="text-muted d-flex justify-content-center">It will be as simple as Occidental in fact it will be Occidental.</p> */}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ComingSoon
