import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Button,
  Input,
  Form,
  FormGroup,
} from "reactstrap";
import classnames from "classnames";
import { Link } from "react-router-dom";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
//Dropzone
import Dropzone from "react-dropzone";
//Import Breadcrumb

//Import images
import verificationImg from "../../assets/images/verification-img.png";
import { addKyc, getDocType, kycDoc } from "assistance/promoterClient_service";
import { fetchCountryList } from "pages/Authentication/Registerfunctions";
import { data } from "jquery";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

interface DocumentType {
  id: string;
  value: string;
  desc: string;
  country: string;
}

interface Country {
  name: string;
  phoneCode: string;
  sortname: string;
}

const Agreement = () => {
  //meta title
  document.title = " Skartio - Insider";

  const [modal, setModal] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<number>(1);
  const [selectedFiles, setSelectedFiles] = useState<any>([]);

  const [countryList, setCountryList] = useState<Country[]>([]);

  const [documentOptions, setDocumentOptions] = useState<DocumentType[]>([]);

  const [selectedDocument, setSelectedDocument] = useState<string>("");
  const [documentNumber, setDocumentNumber] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [formData, setFormData] = useState({
    kycfirstName: "",
    gender: "",
    kycbirthDate: "",
    country: "",
    selectedDocument: "",
    documentNumber: "",
  });



  const [attachment, setAttachment] = useState([]);
  const [fileError, setFileError] = useState("");
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [uploadedFile, setUploadedFile] = useState(null);

 
  const handleAcceptedFiles = async (files) => {
    const acceptedFiles = files.filter(file => {
      return file.type.startsWith('image/');
    });
  
    if (acceptedFiles.length > 0) {
      setAttachment(acceptedFiles[0]); 
      setSelectedFiles(acceptedFiles)
      try {
        const response = await kycDoc(acceptedFiles[0]);
        // console.log(response);
      } catch (error) {
        // console.error("Error uploading file:", error);
        setFileError("There was an error uploading the file."); 
      }
    } else {
      setFileError("Please upload a valid file."); 
    }
    // console.log(attachment);
  };
  


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDateChange = (date) => {
    setFormData((prevData) => ({
      ...prevData,
      kycbirthDate: date[0], // Use date[0] assuming it's a single date
    }));
  };

  const handleSubmit = async (data) =>  {
    // console.log("Form Data:", data); // Log the form data
     
    // console.log("Form Data:", data?.country); // Log the form data
    // console.log("Form Data:", data?.selectedDocument); // Log the form data
    // console.log("Form Data:", data?.kycfirstName); // Log the form data
    // console.log("Form Data:", data?.gender); // Log the form data
    // console.log("Form Data:", data?.documentNumber); // Log the form data
  
    const formattedDate = data?.kycbirthDate.toISOString().split('T')[0];
    // console.log(formattedDate); // Output: "1999-11-10" (in YYYY-MM-DD format)
    
    // Convert to DD-MM-YYYY format
    const day = data?.kycbirthDate.getDate().toString().padStart(2, '0');
    const month = (data?.kycbirthDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
    const year = data?.kycbirthDate.getFullYear();
    const formattedDateDDMMYYYY = `${day}-${month}-${year}`;
    // console.log(formattedDateDDMMYYYY);
  
    try {
      const response = await addKyc(
        data?.kycfirstName,
        data?.gender,
        data?.kycbirthDate,
        "",
        data?.selectedDocument,
        data?.documentNumber,
      );
  
      if (response.data && response.status === 200) {
        toast.success("KYC submission successful!");
      } else if (response.status === 203) {
        toast.warn("Data Is already exists");
      }
    } catch (err) {
      // console.error("Error submitting KYC:", err);
      toast.error("An error occurred while submitting KYC. Please try again.");
    }
  };
  


  
  const handleDocumentChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedDocument(e.target.value);
    setError("");
    setDocumentNumber("");
    setFormData({ ...formData, selectedDocument: e.target.value });
  };

  const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const number = e.target.value;
    setDocumentNumber(number);
    setFormData({ ...formData, documentNumber: e.target.value });
    // Basic validation based on selected document type
    if (selectedDocument === "Aadhaar Card" && !/^\d{12}$/.test(number)) {
      setError("Aadhaar Card must be 12 digits.");
    } else if (
      selectedDocument === "PAN Card" &&
      !/^[A-Z]{5}\d{4}[A-Z]$/.test(number)
    ) {
      setError("PAN Card must be in format: ABCDE1234F.");
    } else if (
      selectedDocument === "Indian Passport" &&
      !/^[A-Z]\d{7}$/.test(number)
    ) {
      setError("Passport must be in format: A1234567.");
    } else {
      setError("");
    }
  };

  useEffect(() => {
    const fetchDocumentTypes = async () => {
      try {
        const response = await getDocType();
        if (response.status === 200) {
          setDocumentOptions(response?.data?.data); // Set the fetched data to state
        }
      } catch (error) {
        console.error("Error fetching document types:", error);
      }
    };

    fetchDocumentTypes();
  }, []);

  useEffect(() => {
    const getCountries = async () => {
      try {
        const countries = await fetchCountryList(); // Call the function from JS file
        setCountryList(countries);
      } catch (err) {
        console.error("Error fetching countries:", err);
      }
    };
    getCountries();
  }, []);

  const togglemodal = () => {
    setModal(!modal);
  };

  const [passedSteps, setPassedSteps] = useState([1]);

  const toggleTab = (tab: any) => {
    if (activeTab !== tab) {
      const modifiedSteps = [...passedSteps];
      modifiedSteps.push(tab);
      setActiveTab(tab);
      setPassedSteps(modifiedSteps);
    }
  };




  return (
    <React.Fragment>
      <div className="">
      <ToastContainer/>

        <Container fluid>

          <Row className="justify-content-center ">
            <Col xl={12} sm={8}>
              <Card>
                <CardBody>
                  <div className="text-center">
                    <Row className="justify-content-center">
                      <Col lg={12}>
                        <h4 className=" fw-semibold"> Manage Agreement </h4>
                        
                        <div className="mt-4">
                          {/* <Button
                            type="button"
                            style={{ backgroundColor: "#642e5c" }}
                            onClick={togglemodal}
                          >
                            Click here for Verification{" "}
                          </Button> */}


                          <Row>
                {/* <h5 className="font-size-14 mb-3">Upload document file for verification</h5> */}
                <div className="kyc-doc-verification ">
                {/* <Dropzone> */}
                {/* <Dropzone > */}
                      {/* {({ getRootProps, getInputProps }) => ( */}
                        {/* <div {...getRootProps({ className: 'dropzone' })}> */}
                          {/* <input {...getInputProps()} /> */}

                          <div className="mb-3  d-flex justify-content-center">

                            <i className="display-4 text-muted bx bxs-cloud-download" style={{ color:"#642e5c"}} ></i></div>
                                            <h5 className="font-size-14 d-flex justify-content-center">Download Documnet</h5>

                          {/* {fileError && <div className="text-danger">{fileError}</div>} */}
                        {/* </div>
                      )} */}
                    {/* </Dropzone> */}
                
                </div>
              </Row>
                        </div>
                      </Col>
                    </Row>
                    <Row className="justify-content-center mt-5 mb-2">
                      <Col sm={6} xs={8}>
                        <div>
                          <img
                            src={verificationImg}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>

             
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Agreement;
